import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Switch from "react-switch";
import { addRecomendation, updateRecomendationById } from "../../services/Recomendation.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import { generateFilePath } from "../Utility/utils";
import { addRecomendationCategory, updateRecomendationCategoryById } from "../../services/RecomendationCategory.service";

export default function AddRecomendationCategory() {
    const navigate = useNavigate()


    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');

    const [isEditing, setIsEditing] = useState(false);
    const [image, setImage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation()

    const [isVeg, setIsVeg] = useState(false);




    const handleAdd = async () => {
        try {



            if (image == "") {
                setLoading(false)
                toastError("image cannot be empty");
                return
            }





            let obj = {
                name,
                isCombo: isVeg,
                imageStr: image,
                petPujaTableId: searchParams.get("tableId"),
                petPujaRestaurantId: searchParams.get("restaurantId"),
            }
            if (!isEditing) {
                let { data: res } = await addRecomendationCategory(obj)
                if (res.message) {
                    toastSuccess(res.message)
                    setLoading(false)
                }
            }
            else {
                let { data: res } = await updateRecomendationCategoryById(obj, searchParams.get("MainCategoryId"))
                if (res.message) {
                    toastSuccess(res.message)
                    setLoading(false)
                }
            }
        }
        catch (err) {
            toastError(err)
            console.log(err)
            setLoading(false)
        }
    }




















    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <DashboardBox>
                        <div className="row">

                            <div className="col-6">
                                <label htmlFor="" className="my-3">Name</label>
                                <input value={`${name}`} type="text" onChange={(e) => setName(e.target.value)} className="form-control" />
                            </div>




                            <div className="col-6 ">
                                <label htmlFor="" className="my-3">Type</label>
                                <div style={{ display: "flex" }}>
                                    <Switch onChange={(e) => { setIsVeg(e) }} checked={isVeg} />
                                    <span style={{ marginLeft: 30 }}>{isVeg ? "Combo?" : "Combo?"}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="" className="my-3">Image</label>
                                {image && `${image}` != "" &&
                                    <div className="my-3">
                                        {
                                            image && `${image}`.includes("base64") ? <img style={{ height: 150, width: 150 }} src={image} /> : <img style={{ height: 150, width: 150 }} src={generateFilePath(image)} />
                                        }
                                    </div>
                                }
                                <FileUpload accepts="image/*" onFileChange={setImage} />
                            </div>

                            <div className="col-12 mt-4">
                            </div>
                        </div>
                        <CustomButton btnName={"Close"} ClickEvent={() => navigate(-1)} isBtn extraClass={"me-3"} iconName="fa-solid fa-close" path="/" />
                        <CustomButton btnName={"Submit"} ClickEvent={() => handleAdd()} isBtn iconName="fa-solid fa-pencil" path="/" />
                    </DashboardBox>
                </div>
            </div>

            {
                loading &&
                <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
                    <h1>Loading Please Wait....</h1>
                </div>
            }
        </div>
    );
}


