import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getRestaurants } from "../../services/restaurant.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardTable } from "../Utility/DashboardBox";
import CustomButton from "../Utility/Button";
import { deleteAllergensById, getAllergens } from "../../services/Allergens.service";
function AllergensList() {
  const [loading, setLoading] = useState(false);

  const [allergensArr, setAllergensArr] = useState([]);


  const handleGetAllergens = async () => {
    try {
      setLoading(true)
      let { data: res } = await getAllergens()
      if (res.data) {
        setLoading(false)
        setAllergensArr(res.data);
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }


  const handleDeleteAllergens = async (id) => {
    try {
      setLoading(true)
      let { data: res } = await deleteAllergensById(id)
      if (res.message) {
        toastSuccess(res.message)
        setLoading(false)
        handleGetAllergens()
      }
    }
    catch (err) {
      toastError(err)
      setLoading(false)
    }
  }





  useEffect(() => {
    handleGetAllergens();
  }, []);


  const product_sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "7%",
    },
    {
      name: "Name",
      cell: (row) => <p>{row?.name}</p>,
      width: "25%",
    },
    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row._id}
            edit
            isRedirected={true}
            editPath={`/Allergens/Edit?allergenId=${row._id}`}
          />
          <span className="ms-3">
            <ActionIcon
              Uniquekey={row._id}
              remove
              onDeleteClick={() => handleDeleteAllergens(row._id)}
              deletePath={"/Allergens"}
              isRedirected={true}
              editPath={`/Allergens`}
            />
          </span>
        </>
      ),
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1">Allergens List</h5>
                <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW" path="/Allergens/Add" />
              </div>
              <DashboardTable>
                <DataTable columns={product_sale_columns} data={allergensArr && allergensArr.length > 0 ? allergensArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>

      {
        loading &&
        <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
          <h1>Loading Please Wait....</h1>
        </div>
      }
    </main>
  );
}

export default AllergensList;
