import React from "react";
import { images } from "../Images/Images";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { rolesObj } from "../Utility/constants";
import { useSelector } from 'react-redux'
function SideBar() {
  const authObj = useSelector(state => state.auth)
  let location = useLocation();
  const [sidebar_item, setsidebar_item] = useState([

    {
      isrotated: false,
      active: false,
      name: "Restaurants",
      path: "/",
      icon: "fa-solid fa-shop",
      children: [],
      role: [rolesObj?.ADMIN, rolesObj?.RESTAURANT, rolesObj?.SUBADMIN]
    },

    {
      isrotated: false,
      active: false,
      name: "Allergens",
      path: "/Allergens",
      icon: "fa-solid fa-hand-dots",
      children: [],
      role: [rolesObj?.ADMIN, rolesObj.RESTAURANT, rolesObj?.SUBADMIN]

    },
    {
      isrotated: false,
      active: false,
      name: "Users",
      path: "/View-Users",
      icon: "fa-solid fa-users",
      children: [],
      role: [rolesObj?.ADMIN,]

    },
    {
      isrotated: false,
      active: false,
      name: "Report",
      path: "/report",
      icon: "fa-solid fa-file",
      children: [
        {
          name: "Total Sales",
          path: "/total-sales",
          icon: "fa-solid fa-tags",
          active: false,
          role: [rolesObj?.ADMIN]

        },
        {
          name: "Total orders",
          path: "/total-orders",
          icon: "fa-solid fa-boxes-stacked",
          active: false,
          role: [rolesObj?.ADMIN, rolesObj.RESTAURANT]

        },
        {
          name: "Trending Products",
          path: "/trending-products",
          icon: "fa-brands fa-product-hunt",
          active: false,
          role: [rolesObj?.ADMIN]

        },
        {
          name: "Watched Videos",
          path: "/watched-videos",
          icon: "fa-solid fa-video",
          active: false,
          role: [rolesObj?.ADMIN]

        },
        {
          name: "Diner Data",
          path: "/Diner-Data",
          icon: "fa-solid fa-user",
          active: false,
          role: [rolesObj?.ADMIN]

        },

      ],
      role: [rolesObj?.ADMIN, rolesObj.RESTAURANT]

    },

  ]);

  const isRotating = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.isrotated = !el.isrotated;
        el.active = true;
      } else {
        el.active = false;
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const childActive = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (el.children.length > 0) {
        el.children.map((item, childIndex) => {
          if (childIndex === i) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  return (
    <div id="sidebar" className="no-print">
      <div className="main-logo">
        <img src={images.logo} alt="" />
      </div>
      <ul className="sidebar-menu" id="sidebarMenu">
        {sidebar_item.filter(el => el.role.some(elx => elx == authObj?.role)).map((item, i) => {
          if (typeof array === "undefined" && item.children.length === 0) {
            return (
              <li key={`sidebar_item_${i}`}>
                <Link
                  to={item.path}
                  className={item.active ? "active" : ""}
                  onClick={() => isRotating(i)}
                >
                  <i className={item.icon}></i>
                  <p className="mb-0">{item.name}</p>
                </Link>
              </li>
            );
          } else {
            return (
              <li key={`sidebar_item_${i}`}>
                <Link
                  to={`#sidebar_item_children_${i}`}
                  className={
                    item.active || location === item.path ? "active" : ""
                  }
                  data-bs-toggle="collapse"
                  aria-expanded={item.active}
                  aria-controls={`sidebar_item_children_${i}`}
                  role="button"
                  onClick={() => isRotating(i)}
                >
                  <i className={item.icon}></i>
                  <p className="mb-0">
                    {item.name}
                    {item.isrotated ? (
                      <i className="ion-arrow-up-b pe-3"></i>
                    ) : (
                      <i className="ion-arrow-down-b pe-3"></i>
                    )}
                  </p>
                </Link>
                <ul
                  className="collapse"
                  id={`sidebar_item_children_${i}`}
                  data-bs-parent="#sidebarMenu"
                >
                  {item.children.filter(elm => elm.role.some(elx => elx == authObj?.role)).map((child, index) => {
                    return (
                      <li key={`${child.name}_${index}`}>
                        <Link
                          to={child.path}
                          className={
                            child.active || location === child.path
                              ? "active"
                              : ""
                          }
                          onClick={() => childActive(index)}
                        >
                          <i className={child.icon}></i>
                          {child.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}

export default SideBar;
