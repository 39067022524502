import { Route, Routes } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/scss/main.css";
import AddAllergens from "../components/Allergens/AddAllergens";
import AllergensList from "../components/Allergens/AllergensList";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import MenuList from "../components/Menu/MenuList";
import AddMainCategory from "../components/Products/AddMainCategory";
import AddProduct from "../components/Products/AddProduct/AddProduct";
import AddRecomendationCategory from "../components/Products/AddRecomendationCategory";
import AddRecomendations from "../components/Products/AddRecomendations";
import ProductList from "../components/Products/ProductList";
import ViewRecomendation from "../components/Products/ViewRecomendation";
import DinerData from "../components/Reports/DinerData";
import TotalOrders from "../components/Reports/TotalOrder";
import TotalSales from "../components/Reports/TotalSales";
import TrendingProducts from "../components/Reports/TrendingProducts";
import WatchedVideos from "../components/Reports/WatchedVideos";
import AddNewRestaurants from "../components/Restaurants/AddNewRestaurants";
import AddRestaurants from "../components/Restaurants/AddRestaurants";
import RestaurantList from "../components/Restaurants/RestaurantList";
import SideBar from "../components/Sidebar/SideBar";
import AddUsers from "../components/Users/AddUsers";
import ViewUsers from "../components/Users/ViewUsers";
export default function AuthorizedRoutes() {
  return (
    <section style={{ backgroundColor: "#ebebeb" }}>
      <div className="row g-0">
        <div className="col-12 col-md-2 no-print" style={{ contain: "content" }}>
          <SideBar />
        </div>
        <div className="col-12 col-md-10" style={{ height: "100vh", overflow: "hidden scroll" }}>
          <Header />
          <Routes>
            {/* <Route exact path="/" element={<Dashboard />}></Route> */}
            {/* <Route exact path="/Dashboard" element={<Dashboard />}></Route> */}
            <Route exact path="/Product/Edit" element={<AddProduct />}></Route>
            <Route exact path="/Product/View" element={<ProductList />}></Route>
            <Route exact path="/Main-Category/Edit" element={<AddMainCategory />}></Route>
            <Route exact path="/Main-Category/Add" element={<AddMainCategory />}></Route>
            <Route exact path="/AddRecomendationCategory" element={<AddRecomendationCategory />}></Route>
            <Route exact path="/ViewRecomendation" element={<ViewRecomendation />}></Route>


            <Route exact path="/Recomendations/Edit" element={<AddRecomendations />}></Route>
            <Route exact path="/Recomendations/Add" element={<AddRecomendations />}></Route>
            <Route exact path="/" element={<RestaurantList />}></Route>
            <Route exact path="/Allergens" element={<AllergensList />}></Route>
            <Route exact path="/Allergens/Add" element={<AddAllergens />}></Route>
            <Route exact path="/Allergens/Edit" element={<AddAllergens />}></Route>
            <Route exact path="/Restaurants/Add" element={<AddNewRestaurants />}></Route>
            <Route exact path="/Restaurants/Edit" element={<AddRestaurants />}></Route>
            <Route exact path="/Restaurants/Edit/:id" element={<AddNewRestaurants />}></Route>
            <Route exact path="/Menu/View" element={<MenuList />}></Route>
            <Route exact path="/total-orders" element={<TotalOrders />}></Route>
            <Route exact path="/total-sales" element={<TotalSales />}></Route>
            <Route exact path="/trending-products" element={<TrendingProducts />}></Route>
            <Route exact path="/watched-videos" element={<WatchedVideos />}></Route>
            <Route exact path="/Add-Users" element={<AddUsers />}></Route>
            <Route exact path="/View-Users" element={<ViewUsers />}></Route>
            <Route exact path="/Diner-Data" element={<DinerData />}></Route>






          </Routes>
          <Footer />
        </div>
      </div>
    </section>
  );
}
