import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/RecomendationCategory";

export const addRecomendationCategory = (formData) => {
    return axios.post(serverUrl + "/addCategory", formData);
};

export const getRecomendationCategory = (query) => {
    return axios.get(`${serverUrl}/getCategory?${query}`);
};


export const getRecomendationCategoryById = (query) => {
    return axios.get(`${serverUrl}/getRecomendationCategoryById/${query}`);
};

export const deleteRecomendationCategoryById = (id) => {
    return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateRecomendationCategoryById = (formData, id) => {
    return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};