import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getPaymentCurrentStatusFromRazorpay, getTotalOrders } from "../../services/index.service";
import { toastError } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSelector } from 'react-redux'

function TotalOrders() {
    const [loading, setLoading] = useState(false);
    const authObj = useSelector(state => state.auth)

    const [productArr, setProductsArr] = useState([]);
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));


    useEffect(() => {
        handleSearch()
    }, [])


    const handleSearch = async () => {
        try {
            setLoading(true)
            let query = `startDate=${startDate}&endDate=${endDate}`
            if (authObj && authObj.role && authObj.role == "RESTAURANT") {
                query = `${query}&userId=${authObj.user.restaurantId}`
            }
            let { data: res } = await getTotalOrders(query);
            if (res.data) {
                console.log(res.data)
                setLoading(false)
                setProductsArr(res.data);
            }
        } catch (error) {
            setLoading(false)
            toastError(error)
        }
    }


    const handlegetPaymentCurrentStatusFromRazorpay = async (id) => {
        try {
            setLoading(true)
            let { data: res } = await getPaymentCurrentStatusFromRazorpay(id);
            if (res.message) {
                handleSearch()
                // console.log(res.data)
                setLoading(false)
                // setProductsArr(res.data);
            }
        } catch (error) {
            setLoading(false)
            console.error(error, "error")
            toastError(error)
        }
    }




    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Invoice Id",
            selector: (row, index) => row?.order_id,
            sortable: true,
        },
        {
            name: "Table No",
            cell: (row) => <p>{row?.tableNo}</p>,
        },
        {
            name: "Restaurant name",
            cell: (row) => <p>{row?.restaurant_name}</p>,
        },
        {
            name: "Amount",
            selector: (row) => row?.total,
            cell: (row) => <p>₹ {row?.total}</p>,
            sortable: true

        },
        {
            name: "Payment Status",
            selector: (row) => row.statusObj?.status,
            cell: (row) => <p style={{ backgroundColor: row?.statusObj?.status == "PENDING" ? "#5c5916" : row?.statusObj?.status == "Payed at restaurant" ? "#0b7d4e" : row?.statusObj?.status == "SUCCESS" ? "green" : "#991818", color: "white", padding: "5px 10px", borderRadius: 10, fontSize: 10 }}>{row?.statusObj?.status ? row?.statusObj?.status : "Payment Incomplete"}</p>,
            sortable: true,
            width: "20%",

        },
        {
            name: "Payment Type",
            selector: (row) => row?.statusObj?.manualBillPayment,
            cell: (row) => <p style={{ backgroundColor: row?.statusObj?.manualBillPayment ? "grey" : "green", color: "white", padding: "5px 10px", borderRadius: 10, fontSize: 10 }}>{row?.statusObj?.manualBillPayment ? "Offline" : "Online"}</p>,
            sortable: true

        },
        {
            name: "Refresh Payment Status",
            cell: (row) => <>
                {
                    !row?.statusObj?.manualBillPayment &&
                    <p onClick={() => handlegetPaymentCurrentStatusFromRazorpay(row._id)} className="btn btn-success">Refresh Payment Status</p>
                }
            </>,
            width: "20%",

        },
        {
            name: "Date",
            selector: (row) => <p>{moment(row?.createdAt).format("DD-MM-YYYY")}</p>,
            sortable: true
        },
    ];




    return (
        <main>
            <div className="row mt-5 py-4">
                <div className="col-12">
                    <h2>Orders</h2>
                </div>

            </div>

            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="row  mb-4">
                                <div className="col-4">
                                    <label>Start Date</label>
                                    <input value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" placeholder="Start Date" type="date" />
                                </div>
                                <div className="col-4">
                                    <label>End Date</label>
                                    <input value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" placeholder="Start Date" type="date" />
                                </div>
                                <div className="col-4">
                                    <CustomButton isBtn iconName="fa-solid" extraClass={"mt-4"} btnName="Search" btntype="button" ClickEvent={() => handleSearch()} />
                                </div>

                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={productArr && productArr.length > 0 ? productArr : []} pagination />


                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
                    <h1>Loading Please Wait....</h1>
                </div>
            }
        </main >
    );
}

export default TotalOrders;
