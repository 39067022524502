import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/allergens";

export const addAllergens = (formData) => {
    return axios.post(serverUrl + "/", formData);
};

export const getAllergens = (query) => {
    return axios.get(`${serverUrl}/?${query}`);
};
export const getAllergenById = (id) => {
    return axios.get(`${serverUrl}/getById/${id}`);
};

export const deleteAllergensById = (id) => {
    return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateAllergensById = (formData, id) => {
    return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};