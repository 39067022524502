import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/recomendation";

export const addRecomendation = (formData) => {
    return axios.post(serverUrl + "/", formData);
};

export const getRecomendations = (query) => {
    return axios.get(`${serverUrl}/?${query}`);
};

export const getRecomendationById = (id) => {
    return axios.get(`${serverUrl}/getById/${id}`);
};

export const deleteRecomendationById = (id) => {
    return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateRecomendationById = (formData, id) => {
    return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};