import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { addAllergens as addMainCategory, getAllergenById as getMainCategoryById, updateAllergensById } from "../../services/Allergens.service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { generateFilePath } from "../Utility/utils";
import FileUpload from "../Utility/FileUpload";
import { getCategory } from "../../services/category.service";
import Select from "react-select";
import { addmaincategory, getmaincategoryById, updatemaincategoryById } from "../../services/MainCategory.service";
import Switch from "react-switch";

export default function AddMainCategory() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [image, setImage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isBeverage, setIsBeverage] = useState(false);
    const location = useLocation()
    const [categoryArr, setCategoryArr] = useState([]);
    const handleAddMainCategories = async () => {
        try {
            setLoading(true)
            if (name == "") {
                setLoading(false)
                toastError("name cannot be empty");
                return
            }
            if (image == "") {
                setLoading(false)
                toastError("image cannot be empty");
                return
            }
            if (selectedCategories && selectedCategories.length == 0) {
                setLoading(false)
                toastError("Please Select atleast one category");
                return
            }

            let tempSelectedCategoryArr = categoryArr.filter(el => selectedCategories.some(ele => ele.value == el.value))



            let obj = {
                name,
                image,
                isBeverage,
                petPujaTableId: searchParams.get("tableId"),
                petPujaRestaurantId: searchParams.get("restaurantId"),
                categoryArr: tempSelectedCategoryArr.map(el => ({ categoryId: el.value, name: el.label, category: el.categoryid }))
            }
            console.log(obj, selectedCategories, "selectedCategoriesselectedCategories")
            if (!isEditing) {
                let { data: res } = await addmaincategory(obj)
                if (res.message) {
                    toastSuccess(res.message)
                    setLoading(false)
                }
            }
            else {
                let { data: res } = await updatemaincategoryById(obj, searchParams.get("MainCategoryId"))
                if (res.message) {
                    toastSuccess(res.message)
                    setLoading(false)
                }
            }
        }
        catch (err) {
            toastError(err)
            setLoading(false)
        }
    }

    const handleGetMainCategoryById = async (id, catArr) => {
        setLoading(true)
        try {
            let { data: res } = await getmaincategoryById(id)
            if (res.message) {
                setLoading(false)
                console.log(res.data);
                setName(res.data.name);
                setImage(res.data.image);
                setIsBeverage(res.data.isBeverage);
                let temp = catArr;
                temp = catArr.filter(el => res.data.categoryArr.some(ele => ele.categoryId == el.value))
                console.log(temp, "temp")
                setSelectedCategories(temp);
                toastSuccess(res.message);
            }
        }
        catch (err) {
            setLoading(false)
            toastError(err)
        }
    }



    const handleGetCategories = async (id) => {
        setLoading(true)
        try {
            let { data: res } = await getCategory(`restaurantId=${searchParams.get("restaurantId")}&tableId=${searchParams.get("tableId")}`)
            if (res.message) {
                setLoading(false)
                let catArr = res.data.map(el => ({ ...el, label: el.categoryname, value: el._id }))
                setCategoryArr(catArr)
                if (searchParams.get("MainCategoryId")) {
                    handleGetMainCategoryById(searchParams.get("MainCategoryId"), catArr);
                }
                // console.log(res.data)
                // setName(res.data.name)
                // setImage(res.data.image)
                // toastSuccess(res.message)
            }
        }
        catch (err) {
            setLoading(false)
            toastError(err)
        }
    }





    useEffect(() => {
        handleGetCategories()
        if (location.pathname.includes("Edit")) {
            setIsEditing(true)
        }
        else {
            setIsEditing(false)
        }
    }, [location, searchParams.get("MainCategoryId")])









    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <DashboardBox>
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="" className="my-3">Name</label>
                                <input onChange={(e) => setName(e.target.value)} value={name} type="text" className="form-control" />
                            </div>
                            <div className="col-6">
                                <label htmlFor="" className="my-3">Category</label>
                                <Select isMulti closeMenuOnSelect={false} onChange={(e) => setSelectedCategories(e)} options={categoryArr} value={selectedCategories} />
                            </div>
                            <div className="col-6 ">
                                <label htmlFor="" className="my-3">Is Beverage</label>
                                <div style={{ display: "flex" }}>
                                    <Switch onChange={(e) => setIsBeverage(e)} checked={isBeverage} />
                                    <span style={{ marginLeft: 30 }}>{isBeverage ? "Is Beverage" : "Is Not beverage"}</span>
                                </div>

                            </div>
                            <div className="col-12">
                                <label htmlFor="" className="my-3">Image</label>
                                {image && `${image}` != "" &&
                                    <div className="my-3">
                                        {
                                            image && `${image}`.includes("base64") ? <img style={{ height: 150, width: 150 }} src={image} /> : <img style={{ height: 150, width: 150 }} src={generateFilePath(image)} />
                                        }
                                    </div>
                                }
                                <FileUpload accepts="image/*" onFileChange={setImage} />
                            </div>

                            <div className="col-12 mt-4">
                            </div>
                        </div>
                        <CustomButton btnName={"Close"} ClickEvent={() => navigate(-1)} isBtn extraClass={"me-3"} iconName="fa-solid fa-close" path="/" />
                        <CustomButton btnName={"Submit"} ClickEvent={() => handleAddMainCategories()} isBtn iconName="fa-solid fa-pencil" path="/" />
                    </DashboardBox>
                </div>
            </div>

            {
                loading &&
                <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
                    <h1>Loading Please Wait....</h1>
                </div>
            }
        </div>
    );
}


