import axios from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/restaurant";

export const addRestaurent = (formData) => {
  return axios.post(serverUrl + "/addRestaurent", formData);
};

export const getRestaurants = (query) => {
  return axios.get(`${serverUrl}/getRestaurants?${query}`);
};

export const getRestaurantsForAdmin = (query) => {
  return axios.get(`${serverUrl}/getRestaurantsForAdmin?${query}`)
}

export const refreshRestaurantRazorpayData = (id) => {
  return axios.get(`${serverUrl}/refreshRestaurantRazorpayData/${id}`);
}

export const getRestaurantsById = (id) => {
  return axios.get(`${serverUrl}/getRestaurantsById/${id}`);
};
export const getRestaurantsMenuByTableById = (id) => {
  return axios.get(`${serverUrl}/getRestaurantsMenuByTableById/${id}`);
};

export const deleteProductById = (id) => {
  return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateRestaurantById = (formData, id) => {
  return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const downloadFile = async (text) => {
  return axios.get(`${serverUrl}/downloadQr?query=${text}`);
};

export const SyncRestaurantWithApi = async (id) => {
  return axios.get(`${serverUrl}/SyncRestaurant/${id}`)
}
