//////local url
// export const url = "http://localhost:4021";


///live url
export const url = "/api";
// export const url = "https://cms.dash.menu//api";


////////qr download url
// export const tempUrl = "http://192.168.0.31:3000/splashscreen";


////////live qr download url
export const tempUrl = "https://app.dash.menu/splashscreen";
