import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/table";


export const getTableMenuById = (id) => {
    return axios.get(`${serverUrl}/geTableMenu/${id}`);
};
export const updateRestaurantById = (formData, id) => {
    return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};