import React, { useEffect, useState } from "react";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { addAllergens, getAllergenById, updateAllergensById } from "../../services/Allergens.service";
import { useLocation, useSearchParams } from "react-router-dom";
import { generateFilePath } from "../Utility/utils";
import FileUpload from "../Utility/FileUpload";

export default function AddAllergens() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [image, setImage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation()
  const handleAddAllergens = async () => {
    try {
      setLoading(true)
      if (name == "") {
        toastError("name cannot be empty");
        return
        setLoading(false)
      }


      let obj = {
        name,
        image
      }
      if (!isEditing) {
        let { data: res } = await addAllergens(obj)
        if (res.message) {
          toastSuccess(res.message)
          setLoading(false)
        }
      }
      else {
        let { data: res } = await updateAllergensById(obj, searchParams.get("allergenId"))
        if (res.message) {
          toastSuccess(res.message)
          setLoading(false)
        }
      }
    }
    catch (err) {
      toastError(err)
      setLoading(false)
    }
  }

  const handleGetAllergenById = async (id) => {
    setLoading(true)
    try {
      let { data: res } = await getAllergenById(id)
      if (res.message) {
        setLoading(false)
        console.log(res.data)
        setName(res.data.name)
        setImage(res.data.image)
        toastSuccess(res.message)
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }





  useEffect(() => {
    if (searchParams.get("allergenId")) {
      handleGetAllergenById(searchParams.get("allergenId"));
    }
    if (location.pathname.includes("Edit")) {
      setIsEditing(true)
    }
    else {
      setIsEditing(false)
    }
  }, [location, searchParams.get("allergenId")])



  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <DashboardBox>
            <div className="row">
              <div className="col-6">
                <label htmlFor="">Allergen Name</label>
                <input onChange={(e) => setName(e.target.value)} value={name} type="text" className="form-control" />
              </div>
              <div className="col-12">
                <label htmlFor="" className="mb-3">Image</label>
                {image && `${image}` != "" &&
                  <div className="my-3">
                    {
                      image && `${image}`.includes("base64") ? <img style={{ height: 150, width: 150 }} src={image} /> : <img style={{ height: 150, width: 150 }} src={generateFilePath(image)} />
                    }
                  </div>
                }
                <FileUpload accepts="image/*" onFileChange={setImage} />
              </div>
              {/* <div className="col-6"></div> */}
              <div className="col-12 mt-4">
              </div>
            </div>
            <CustomButton btnName={"Submit"} ClickEvent={() => handleAddAllergens()} isBtn iconName="fa-solid fa-pencil" path="/" />
          </DashboardBox>
        </div>
      </div>

      {
        loading &&
        <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
          <h1>Loading Please Wait....</h1>
        </div>
      }
    </div>
  );
}


