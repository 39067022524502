import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getRestaurants, getRestaurantsForAdmin, updateRestaurantById } from "../../services/restaurant.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardTable } from "../Utility/DashboardBox";
import CustomButton from "../Utility/Button";
import { useSelector } from 'react-redux'
import { accessObj, rolesObj } from "../Utility/constants";
function RestaurantList() {
  const [loading, setLoading] = useState(false);
  const authObj = useSelector(state => state.auth)
  const [restaurantArr, setRestaurantArr] = useState([]);


  const handleGetRestaurants = async () => {
    try {
      setLoading(true)
      let query = authObj?.user?.restaurantId ? `restaurantId=${authObj?.user?.restaurantId}` : null
      if (authObj?.user?.restaurantId) {
        await localStorage.setItem("RESTAURANT", authObj?.user?.restaurantId)
      }

      if (authObj?.role == rolesObj?.ADMIN) {
        let { data: res } = await getRestaurantsForAdmin(query)
        if (res.data) {
          setLoading(false)
          setRestaurantArr(res.data);
        }
      }
      else {

        let { data: res } = await getRestaurants(query)
        if (res.data) {
          setLoading(false)

          setRestaurantArr(res.data);
        }
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }


  const handleSwitch = async (id, value) => {
    try {
      setLoading(true)
      let obj = {
        isActive: value
      }

      const { data: res } = await updateRestaurantById(obj, id);
      if (res) {
        setLoading(false)
        toastSuccess(res.message)
        handleGetRestaurants()
      }
    } catch (error) {
      setLoading(false)
      toastError(error)
    }
  }


  const checkDisplayPermissions = (checkPermission) => {
    if (authObj?.role == rolesObj.SUBADMIN) {
      let tempArr = authObj?.user?.accessArr;
      if (tempArr.some(el => el?.value == checkPermission)) {
        return true
      }
      else {
        return false
      }
    }
    else if (authObj?.role == rolesObj?.ADMIN) {
      return true
    }
  }


  useEffect(() => {
    handleGetRestaurants();
  }, []);


  const product_sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "7%",
    },
    {
      name: "PetPuja Id (Menu Sharing code)",
      cell: (row) => <p>{row?.menu_sharing_code}</p>,
      width: "18%",
    },
    {
      name: "Name",
      cell: (row) => <p>{row?.restaurantname}</p>,
      width: "25%",
    },
    {
      name: "Status",
      cell: (row) => <p>{row?.isActive ? "Active" : "Inactive"}</p>,
      width: "7%",
    },
    {
      name: "No. of Tables",
      selector: (row) => row?.tableArr,
      width: "8%",
    },
    // {
    //   name: "Categories",
    //   selector: (row) => row?.categoryArr,
    //   width: "12%",
    // },
    {
      name: "Action",
      width: "6%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            edit
            isRedirected={true}
            editPath={`/Restaurants/Edit?restaurantId=${row.menu_sharing_code}`}
          />
        </>
      ),
    },
    {
      name: "Enable",
      width: "15%",
      cell: (row) => (
        <>
          {authObj?.role == rolesObj?.ADMIN && checkDisplayPermissions(accessObj?.edit_restaurant) &&
            <>
              <CustomButton isBtn iconName="fa-solid" extraClass={" me-2"} isDisabled={!row.isActive} btnName="Inactive" btntype="button" ClickEvent={() => handleSwitch(row?.menu_sharing_code, false)} />
              <CustomButton isBtn iconName="fa-solid" isDisabled={row?.isActive} btnName="Active" btntype="button" ClickEvent={() => handleSwitch(row?.menu_sharing_code, true)} />
            </>
          }

        </>
      ),
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1">Restaurant List</h5>
                {
                  authObj?.role != rolesObj?.RESTAURANT && checkDisplayPermissions(accessObj.add_restaurant) &&

                  <CustomButton isLink iconName="fa-solid fa-plus" btnName="ADD NEW" path="/Restaurants/Add" />
                }
              </div>
              <DashboardTable>
                <DataTable columns={product_sale_columns} data={restaurantArr && restaurantArr.length > 0 ? restaurantArr : []} pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
      {
        loading &&
        <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
          <h1>Loading Please Wait....</h1>
        </div>
      }
    </main>
  );
}

export default RestaurantList;
