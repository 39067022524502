import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getWatchedVideos } from "../../services/index.service";
import { toastError } from "../../utils/toastUtils";
import { DashboardTable } from "../Utility/DashboardBox";
import CustomButton from "../Utility/Button";

function WatchedVideos() {
    const [productArr, setProductsArr] = useState([]);
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const getSales = async () => {
        try {
            let { data: res } = await getWatchedVideos();
            if (res.data) {
                console.log(res.data)
                setProductsArr(res.data);
            }
        } catch (err) {
            toastError(err);
        }
    };

    useEffect(() => {
        handleSearch()
    }, [])







    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Product",
            cell: (row) => <p>{row?.itemname}</p>,
        },
        {
            name: "Wactched Count",
            selector: (row) => row?.ordered_times,
            cell: (row) => <p>{row?.ordered_times}</p>,
            sortable: true
        },


    ];


    const handleSearch = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`
            console.log("SEA")
            let { data: res } = await getWatchedVideos(query);
            if (res.data) {
                console.log(res.data)
                setProductsArr(res.data);
            }
        } catch (error) {
            toastError(error)
        }
    }








    return (
        <main>
            <div className="row mt-5 py-4">
                <div className="col-12">
                    <h2>
                        Watched Videos
                    </h2>
                </div>

            </div>

            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="row  mb-4">
                                <div className="col-4">
                                    <label>Start Date</label>
                                    <input value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" placeholder="Start Date" type="date" />
                                </div>
                                <div className="col-4">
                                    <label>End Date</label>
                                    <input value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" placeholder="Start Date" type="date" />
                                </div>
                                <div className="col-4">
                                    <CustomButton isBtn iconName="fa-solid" extraClass={"mt-4"} btnName="Search" btntype="button" ClickEvent={() => handleSearch()} />
                                </div>

                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={productArr && productArr.length > 0 ? productArr : []} pagination />


                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="row d-flex align-items-center justify-content-between mb-4">


                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </main >
    );
}

export default WatchedVideos;
