import React, { useEffect, useState } from "react";
import { DashboardBox } from "../../Utility/DashboardBox";
import CustomButton from "../../Utility/Button";
import FileUpload from "../../Utility/FileUpload";
import { generateFilePath } from "../../Utility/utils";
import { getSingleProductByRestaurantTableAndProductId, updateProductById } from "../../../services/product.service";
import { toastError, toastSuccess } from "../../../utils/toastUtils";
import { useSearchParams } from "react-router-dom";
import { getAllergens } from "../../../services/Allergens.service";
import { getTableMenuById } from "../../../services/Table.service";

import Select from 'react-select';
import { accessObj, rolesObj } from "../../Utility/constants";
import { useSelector } from 'react-redux'
function GeneralProduct() {
  const authObj = useSelector(state => state.auth)


  const [productsArr, setProductsArr] = useState([]);
  const [mainProductsArr, setMainProductsArr] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [selectedProductsFromBackend, setSelectedProductsFromBackend] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isAlcoholic, setIsAlcoholic] = useState(false);
  const [ingredientsArr, setIngredientsArr] = useState([{ name: "" }]);
  const [allergensArr, setAllergensArr] = useState([{ name: "" }]);
  const [mainAllergensArr, setMainAllergensArr] = useState([]);
  const [isVine, setIsVine] = useState(false);
  const [pronunciation, setPronunciation] = useState("");
  const [item_image_url, setItem_image_url] = useState("");
  const [productObj, setProductObj] = useState({});
  const [video, setVideo] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [txt, setTxt] = useState("");
  const handleAddProductIngredients = () => {
    let tempArr = ingredientsArr
    tempArr.push({ name: "" })
    setIngredientsArr([...tempArr])
  }
  const handleDeleteProductIngredients = () => {
    if ((ingredientsArr.length - 1) > 0) {
      let tempArr = ingredientsArr.filter((el, index) => (index != (ingredientsArr.length - 1)))
      setIngredientsArr([...tempArr])
    }
  }
  const handleAddValuetoIngredients = (value, index) => {
    let tempArr = ingredientsArr
    tempArr[index].name = value
    setIngredientsArr([...tempArr])
  }


  // const handleAddProductAllergens = () => {
  //   let tempArr = allergensArr
  //   tempArr.push({ name: "" })
  //   setAllergensArr([...tempArr])
  // }
  // const handleDeleteProductAllergens = () => {
  //   if ((allergensArr.length - 1) > 0) {
  //     let tempArr = allergensArr.filter((el, index) => (index != (allergensArr.length - 1)))
  //     setAllergensArr([...tempArr])
  //   }
  // }
  // const handleAddValuetoAllergens = (value, index) => {
  //   let tempArr = allergensArr
  //   tempArr[index].name = value
  //   setAllergensArr([...tempArr])
  // }



  const handleGetProductsByTableId = async (id) => {
    try {
      setLoading(true)
      let { data: res } = await getTableMenuById(id);
      console.log(res.data, "handleGetProductsByTableId")
      if (res.data) {
        setLoading(false)
        let tempArr = res.data.map((el, index) => ({ label: el.itemname, value: el._id, ...el })).filter(el => (el.itemid != searchParams.get("productId")))
        console.log(res.data)
        setProductsArr([...tempArr]);
        setMainProductsArr([...tempArr]);
      }
    } catch (err) {
      setLoading(false)
      toastError(err);
    }
  };





  useEffect(() => {
    if (searchParams.get("tableId")) {
      handleGetProductsByTableId(searchParams.get("tableId"))
    }
  }, [searchParams.get("tableId")])



  const handleProductUpdate = async (value) => {
    try {
      setLoading(true)

      if (isVine && pronunciation == "") {
        setLoading(false)
        toastError("Please fill pronunciation because you have marked this product as vine so pronunciation is mandatory !!!")
        return
      }

      console.log("allergensArr", allergensArr)

      let obj = {
        isAlcoholic,
        ingredientsArr,
        allergensArr: allergensArr.map((el, index) => ({ name: el.label, allergenId: el.value, image: el.image })),
        isVine,
        pronunciation,
        item_image_url,
        video,
        txt,
        updateForAll: false
      };


      if (selectedProducts && selectedProducts.length > 0) {
        obj.relatedProducts = selectedProducts.map(el => ({
          // ...el,
          itemid: el.itemid,
          itemname: el.itemname,
          productId: el._id,
          petPujaTableId: el.petPujaTableId,
          petPujaRestaurantId: el.petPujaRestaurantId,
        }))
      }


      if (value == "updateForAll") {
        obj.updateForAll = true
      }
      console.log(obj, "object")

      let { data: res } = await updateProductById(obj, searchParams.get("productId"), searchParams.get("restaurantId"), searchParams.get("tableId"));
      if (res.message) {
        toastSuccess(res.message);
        handleGetProduct(searchParams.get("productId"), searchParams.get("restaurantId"), searchParams.get("tableId"))
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      toastError(err);
    }
  };




  const handleGetAllergens = async () => {
    try {
      // setLoading(true)

      let { data: res } = await getAllergens();
      if (res.message) {
        // setLoading(false)
        setMainAllergensArr(res.data);
      }
    } catch (err) {
      toastError(err);
      // setLoading(false)
    }
  };

  const handleGetProduct = async (productId, restaurantId, tableId) => {
    try {

      setLoading(true)
      let { data: res } = await getSingleProductByRestaurantTableAndProductId(`productId=${productId}&restaurantId=${restaurantId}&tableId=${tableId}`);
      if (res.message) {
        console.log(res.data, "productObj")
        setItem_image_url(res.data.item_image_url);
        setProductObj(res.data)
        // setLoading(false)
        setVideo(res.data.video);
        if (res.data.ingredientsArr && res.data.ingredientsArr.length > 0) {
          setIngredientsArr(res.data.ingredientsArr);
        }
        console.log(res.data.allergensArr, "res.data.allergensArr")
        // if (res.data.allergensArr && res.data.allergensArr.length > 0) {
        setAllergensArr(res.data.allergensArr.map(el => ({ label: el.name, value: el.allergenId })));
        // }
        setIsAlcoholic(res.data.isAlcoholic);
        setIsVine(res.data.isVine);
        setPronunciation(res.data.pronunciation);
        setSelectedProductsFromBackend(res.data.relatedProducts)
        setTxt(res.data.txt ? res.data.txt : res.data.itemname);
      }
    } catch (err) {
      toastError(err);
      // setLoading(false)
    }
  };



  const checkDisplayPermissions = (checkPermission) => {
    if (authObj?.role == rolesObj.SUBADMIN) {
      let tempArr = authObj?.user?.accessArr;
      if (tempArr.some(el => el?.value == checkPermission)) {
        return true
      }
      else {
        return false
      }
    }
    else if (authObj?.role == rolesObj?.ADMIN) {
      return true
    }
  }

  useEffect(() => {
    if (selectedProductsFromBackend && productsArr && selectedProductsFromBackend.length > 0 && productsArr.length > 0) {
      setSelectedProducts([...productsArr.filter(el => selectedProductsFromBackend.some((ele) => ele.productId == el._id))])
    }
  }, [selectedProductsFromBackend, productsArr])


  useEffect(() => {
    if (searchParams.get("productId") && searchParams.get("restaurantId") && searchParams.get("tableId")) {
      handleGetProduct(searchParams.get("productId"), searchParams.get("restaurantId"), searchParams.get("tableId"))
      handleGetAllergens()
    }
  }, [searchParams])
  return (
    <DashboardBox>
      <h4 className="blue-1" style={{ borderBottom: "solid 1px", width: "max-content", marginBottom: 20 }}>Entering details for {productObj.itemname}</h4>
      <div className="col-12 mt-3">
        <label htmlFor="">Please add text</label>
        <input type="text" placeholder="Please add text" onChange={(e) => setTxt(e.target.value)} value={txt} className="form-control" />
      </div>
      <div className="row mt-3">
        <div className="col-9">
          <h5>Ingredients</h5>
        </div>
        <div className="col-3 ms-auto d-flex justify-content-end">
          <CustomButton extraClass={"ms-2"} ClickEvent={handleAddProductIngredients} isBtn iconName="fa-solid fa-add" path="/Restaurants/Edit" />
          <CustomButton extraClass={"ms-2"} ClickEvent={handleDeleteProductIngredients} isBtn iconName="fa-solid fa-minus" path="/Restaurants/Edit" />
        </div>
        <div className="row">
          {
            ingredientsArr && ingredientsArr.length > 0 && ingredientsArr.map((el, index) => {
              return (
                <div className="col-6" key={index}>
                  <label htmlFor="">Ingredient {index + 1}</label>
                  <input type="text" placeholder="Please enter Product Ingredients" onChange={(e) => handleAddValuetoIngredients(e.target.value, index)} value={el.name} className="form-control" />
                </div>
              )
            })
          }
        </div>
      </div>

      <hr />
      <div className="row">
        <div className="col-9">
          <h5>Allergens</h5>
        </div>
        <div className="row">
          <Select
            // defaultValue={[colourOptions[2], colourOptions[3]]}
            isMulti
            onChange={(val) => { setAllergensArr(val); console.log(val) }}
            // name="colors"
            value={allergensArr}
            closeMenuOnSelect={false}
            options={mainAllergensArr.map(el => ({ label: el.name, value: el._id, image: el.image }))}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </div>
        {/* <div className="col-3 ms-auto d-flex justify-content-end">
          <CustomButton extraClass={"ms-2"} ClickEvent={handleAddProductAllergens} isBtn iconName="fa-solid fa-add" path="/Restaurants/Edit" />
          <CustomButton extraClass={"ms-2"} ClickEvent={handleDeleteProductAllergens} isBtn iconName="fa-solid fa-minus" path="/Restaurants/Edit" />
        </div>
        <div className="row">
          {
            allergensArr && allergensArr.length > 0 && allergensArr.map((el, index) => {
              return (
                <div className="col-6" key={index}>
                  <label htmlFor="">Allergens {index + 1}</label>
                  <input type="text" placeholder="Please enter Product Allergens" onChange={(e) => handleAddValuetoAllergens(e.target.value, index)} value={el.name} className="form-control" />
                </div>
              )
            })
          }
        </div> */}
      </div>


      <hr />
      <div className="row mt-3">
        <div className="col-9">
          <h5>If its a beverage then is it alcoholic or not ?</h5>
        </div>
        <div className="col-6 mt-3">
          <label htmlFor="">Is it alcoholic ?</label>
          <div className="row">
            <div className="col-6">
              <input type="checkbox" name="alcoholic" id="alcoholic" placeholder="Please enter Product Allergens" onChange={(e) => setIsAlcoholic(true)} checked={isAlcoholic} />
              <label htmlFor="alcoholic" style={{ marginLeft: 10 }}>Yes</label>
            </div>
            <div className="col-6">
              <input type="checkbox" name="non-alcoholic" id="non-alcoholic" placeholder="Please enter Product Allergens" onChange={(e) => setIsAlcoholic(false)} checked={!isAlcoholic} />
              <label htmlFor="non-alcoholic" style={{ marginLeft: 10 }}>No</label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row mt-3">
        <div className="col-9">
          <h5>Is it any type of a wine , if yes please add pronunciation too?</h5>
        </div>
        <div className="col-6 mt-3">
          <label htmlFor="">Is it wine ?</label>
          <div className="row">
            <div className="col-6">
              <input type="checkbox" name="is-vine" id="is-vine" placeholder="Please enter Product Allergens" onChange={(e) => setIsVine(true)} checked={isVine} />
              <label htmlFor="is-vine" style={{ marginLeft: 10 }}>Yes</label>
            </div>
            <div className="col-6">
              <input type="checkbox" name="not-vine" id="not-vine" placeholder="Please enter Product Allergens" onChange={(e) => setIsVine(false)} checked={!isVine} />
              <label htmlFor="not-vine" style={{ marginLeft: 10 }}>No</label>
            </div>
          </div>
        </div>
        {
          isVine &&
          <div className="col-12 mt-3">
            <label htmlFor="">Please add pronunciation</label>
            <input type="text" placeholder="Please add pronunciation" onChange={(e) => setPronunciation(e.target.value)} value={pronunciation} className="form-control" />
          </div>
        }
      </div>
      <hr />
      {checkDisplayPermissions(accessObj?.add_images) &&

        <div className="row mt-3">
          <div className="col-9">
            <h5>Product Image</h5>
          </div>
          <div className="col-6">
            {item_image_url ? (
              <>
                {
                  item_image_url.includes("base64") ?
                    <a href={item_image_url} target="_blank">
                      <img style={{ height: 100 }} src={item_image_url} alt="" />
                    </a>
                    :
                    <a href={generateFilePath(item_image_url)} target="_blank">
                      <img style={{ height: 100 }} src={generateFilePath(item_image_url)} alt="" />
                    </a>
                }
                <div className="my-4">
                  <FileUpload onFileChange={(e) => setItem_image_url(e)} />
                </div>
              </>
            ) : (
              <FileUpload onFileChange={(e) => setItem_image_url(e)} />
            )}{" "}
          </div>
        </div>
      }

      <hr />
      {
        checkDisplayPermissions(accessObj?.add_videos) &&
        <div className="row mt-3">
          <div className="col-9">
            <h5>Product Video</h5>
          </div>
          <div className="col-6">
            {video ? (
              <>
                <a href={generateFilePath(video)} target="_blank">
                  <video src={generateFilePath(video)} style={{ height: 100 }} controls></video>
                  <img style={{ height: 100 }} src={generateFilePath(video)} alt="" />
                </a>
                <div className="my-4">
                  <FileUpload accepts="video/mp4,video/x-m4v,video/*" onFileChange={(e) => setVideo(e)} />
                </div>
              </>
            ) : (
              <FileUpload accepts="video/mp4,video/x-m4v,video/*" onFileChange={(e) => setVideo(e)} />
            )}
          </div>
        </div>
      }

      <div className="col-6">
        <label htmlFor="" className="my-3">Related Products</label>
        <Select isMulti closeMenuOnSelect={false} onChange={(e) => setSelectedProducts(e)} options={productsArr} value={selectedProducts} />
      </div>
      <div className="col-12 mt-4">
        <CustomButton btnName={"Submit"} ClickEvent={handleProductUpdate} isBtn iconName="fa-solid fa-pencil" path="/Restaurants/Edit" />
        {/* <CustomButton btnName={"Update this product for all restaurants"} ClickEvent={() => handleProductUpdate("updateForAll")} extraClass={"ms-3"} isBtn iconName="fa-solid fa-pencil" path="/Restaurants/Edit" /> */}
      </div>
      {
        loading &&
        <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
          <h1>Loading Please Wait....</h1>
        </div>
      }

    </DashboardBox >
  )
}

export default GeneralProduct;
