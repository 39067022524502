import { combineReducers } from "redux";

import { authReducer } from "./auth/auth.reducer";
import { ProductReducer } from "./Product/Product.reducer";
import { userReducer } from "./Users/users.reducer";

const RootReducer = combineReducers({
  auth: authReducer,
  users: userReducer,
  product: ProductReducer,
});

export default RootReducer;
