import React, { useState, useEffect } from "react";
import { addRestaurent, getRestaurantsById, refreshRestaurantRazorpayData } from "../../services/restaurant.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateRestaurantById } from "../../services/restaurant.service";
import { razorPayBusinessType, razorPaySubCategory } from "../Utility/constants";
import { LineWeightSharp } from "@mui/icons-material";


const panRegexp = /^[a-zA-z]{5}\d{4}[a-zA-Z]{1}$/

export default function AddNewRestaurants() {
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [password, setPassword] = useState("");
  const [restaurantObj, setRestaurantObj] = useState({});
  const { id } = useParams()
  const [restaurantID, setRestaurantID] = useState("");
  const navigate = useNavigate()

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [reference_id, setReference_id] = useState("");
  const [legal_business_name, setLegal_business_name] = useState("");
  const [business_type, setBusiness_type] = useState("");
  const [contact_name, setContact_name] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [pan, setPan] = useState("");
  const [gst, setGst] = useState("");


  const [manuallyEnabled, setManuallyEnabled] = useState(false)
  const [manuallyEnabledFromServer, setManuallyEnabledFromServer] = useState(false)
  const [accountId, setAccountId] = useState('')


  // razorpay details


  const [razorpayConfig, setRazorpayConfig] = useState({})

  const [razorpayError, setRazorpayError] = useState('')


  const [linked_account_details__email, setLinked_account_details__email] = useState('');
  const [linked_account_details__phone, setLinked_account_details__phone] = useState('');
  const [linked_account_details__type, setLinked_account_details__type] = useState('route'); // this is a static value "route"
  const [linked_account_details__reference_id, setLinked_account_details__reference_id] = useState('');// this will be _id set from backend
  const [linked_account_details__legal_business_name, setLinked_account_details__legal_business_name] = useState('');
  const [linked_account_details__business_type, setLinked_account_details__business_type] = useState('');
  const [linked_account_details__contact_name, setLinked_account_details__contact_name] = useState('');
  const [linked_account_details__profile__category, setLinked_account_details__profile__category] = useState('food'); // this is always be food
  const [linked_account_details__profile__subcategory, setLinked_account_details__profile__subcategory] = useState('');
  const [linked_account_details__profile__addresses__registered__street1, setLinked_account_details__profile__addresses__registered__street1] = useState('');
  const [linked_account_details__profile__addresses__registered__street2, setLinked_account_details__profile__addresses__registered__street2] = useState('');
  const [linked_account_details__profile__addresses__registered__city, setLinked_account_details__profile__addresses__registered__city] = useState('');
  const [linked_account_details__profile__addresses__registered__state, setLinked_account_details__profile__addresses__registered__state] = useState('');
  const [linked_account_details__profile__addresses__registered__postal_code, setLinked_account_details__profile__addresses__registered__postal_code] = useState('');
  const [linked_account_details__profile__addresses__registered__country, setLinked_account_details__profile__addresses__registered__country] = useState('');
  const [linked_account_details__legal_info__pan, setLinked_account_details__legal_info__pan] = useState('');
  const [linked_account_details__legal_info__gst, setLinked_account_details__legal_info__gst] = useState('');

  const [stake_holder__name, setStake_holder__name] = useState('');
  const [stake_holder__email, setStake_holder__email] = useState('');
  const [stake_holder__addresses__residential__street, setStake_holder__addresses__residential__street] = useState('');
  const [stake_holder__addresses__residential__city, setStake_holder__addresses__residential__city] = useState('');
  const [stake_holder__addresses__residential__state, setStake_holder__addresses__residential__state] = useState('');
  const [stake_holder__addresses__residential__postal_code, setStake_holder__addresses__residential__postal_code] = useState('');
  const [stake_holder__addresses__residential__country, setStake_holder__addresses__residential__country] = useState('');
  const [stake_holder__kyc__pan, setStake_holder__kyc__pan] = useState('');
  // const [stake_holder__notes__some_notes, setStake_holder__notes__some_notes] = useState('some notes');

  const [product_configuration__settlements__account_number, setProduct_configuration__settlements__account_number] = useState('');
  const [product_configuration__settlements__ifsc_code, setProduct_configuration__settlements__ifsc_code] = useState('');
  const [product_configuration__settlements__beneficiary_name, setProduct_configuration__settlements__beneficiary_name] = useState('');
  const [product_configuration__tnc_accepted, setProduct_configuration__tnc_accepted] = useState(true);

  // razorpay editable or disabled
  const [linked_account_details__email_disabled, setLinked_account_details__email_disabled] = useState(false);
  const [linked_account_details__phone_disabled, setLinked_account_details__phone_disabled] = useState(false);
  const [linked_account_details__type_disabled, setLinked_account_details__type_disabled] = useState(false);
  const [linked_account_details__reference_id_disabled, setLinked_account_details__reference_id_disabled] = useState(false);
  const [linked_account_details__legal_business_name_disabled, setLinked_account_details__legal_business_name_disabled] = useState(false);
  const [linked_account_details__business_type_disabled, setLinked_account_details__business_type_disabled] = useState(false);
  const [linked_account_details__contact_name_disabled, setLinked_account_details__contact_name_disabled] = useState(false);
  const [linked_account_details__profile__category_disabled, setLinked_account_details__profile__category_disabled] = useState(false);
  const [linked_account_details__profile__subcategory_disabled, setLinked_account_details__profile__subcategory_disabled] = useState(false);
  const [linked_account_details__profile__addresses__registered__street1_disabled, setLinked_account_details__profile__addresses__registered__street1_disabled] = useState(false);
  const [linked_account_details__profile__addresses__registered__street2_disabled, setLinked_account_details__profile__addresses__registered__street2_disabled] = useState(false);
  const [linked_account_details__profile__addresses__registered__city_disabled, setLinked_account_details__profile__addresses__registered__city_disabled] = useState(false);
  const [linked_account_details__profile__addresses__registered__state_disabled, setLinked_account_details__profile__addresses__registered__state_disabled] = useState(false);
  const [linked_account_details__profile__addresses__registered__postal_code_disabled, setLinked_account_details__profile__addresses__registered__postal_code_disabled] = useState(false);
  const [linked_account_details__profile__addresses__registered__country_disabled, setLinked_account_details__profile__addresses__registered__country_disabled] = useState(false);
  const [linked_account_details__legal_info__pan_disabled, setLinked_account_details__legal_info__pan_disabled] = useState(false);
  const [linked_account_details__legal_info__gst_disabled, setLinked_account_details__legal_info__gst_disabled] = useState(false);

  const [stake_holder__name_disabled, setStake_holder__name_disabled] = useState(false);
  const [stake_holder__email_disabled, setStake_holder__email_disabled] = useState(false);
  const [stake_holder__addresses__residential__street_disabled, setStake_holder__addresses__residential__street_disabled] = useState(false);
  const [stake_holder__addresses__residential__city_disabled, setStake_holder__addresses__residential__city_disabled] = useState(false);
  const [stake_holder__addresses__residential__state_disabled, setStake_holder__addresses__residential__state_disabled] = useState(false);
  const [stake_holder__addresses__residential__postal_code_disabled, setStake_holder__addresses__residential__postal_code_disabled] = useState(false);
  const [stake_holder__addresses__residential__country_disabled, setStake_holder__addresses__residential__country_disabled] = useState(false);
  const [stake_holder__kyc__pan_disabled, setStake_holder__kyc__pan_disabled] = useState(false);
  // const [stake_holder__notes__some_notes_disabled, setStake_holder__notes__some_notes_disabled] = useState(false);

  const [product_configuration__settlements__account_number_disabled, setProduct_configuration__settlements__account_number_disabled] = useState(false);
  const [product_configuration__settlements__ifsc_code_disabled, setProduct_configuration__settlements__ifsc_code_disabled] = useState(false);
  const [product_configuration__settlements__beneficiary_name_disabled, setProduct_configuration__settlements__beneficiary_name_disabled] = useState(false);
  const [product_configuration__tnc_accepted_disabled, setProduct_configuration__tnc_accepted_disabled] = useState(false);


  //////////x
  // const [profile, setProfile] = useState({
  //   registered: {
  //     street1: "",
  //     street2: "",
  //     city: "",
  //     state: "",
  //     postal_code: "",
  //   },
  // });
  // const [legal_info, setLegal_info] = useState({
  //   pan: "",
  //   gst: "",
  // });
  //////////x


  //////final Format
  //   {
  //     "email":"gaurav.kumar@example.com",
  //     "phone":"9000090000",
  //     "type":"route",
  //     "reference_id":"124124",
  //     "legal_business_name":"Acme Corp",
  //     "business_type":"partnership",
  //     "contact_name":"Gaurav Kumar",
  //     "profile":{
  //        "category":"healthcare",
  //        "subcategory":"clinic",
  //        "addresses":{
  //           "registered":{
  //              "street1":"507, Koramangala 1st block",
  //              "street2":"MG Road",
  //              "city":"Bengaluru",
  //              "state":"KARNATAKA",
  //              "postal_code":"560034",
  //              "country":"IN"
  //           }
  //        }
  //     },
  //     "legal_info":{
  //        "pan":"AAACL1234C",
  //        "gst":"18AABCU9603R1ZM"
  //     }
  //  }



  const handleGetRestaurantsById = async () => {
    setLoading(true)
    try {

      try {
        await refreshRestaurantRazorpayData(id)
      } catch (error) {
        console.error(error)
      }


      let { data: res } = await getRestaurantsById(id);

      if (res.data) {
        console.log(res.data, "data")
        setRestaurantObj(res.data);
        setVideo(res.data.video);
        setRestaurantID(res.data.menu_sharing_code)
        setEmail(res?.data?.razorpayConfigs?.email)
        setPhone(res?.data?.razorpayConfigs?.phone)
        setType(res?.data?.razorpayConfigs?.type)
        setReference_id(res?.data?.razorpayConfigs?.reference_id)
        setLegal_business_name(res?.data?.razorpayConfigs?.legal_business_name)
        setBusiness_type(res?.data?.razorpayConfigs?.business_type)
        setContact_name(res?.data?.razorpayConfigs?.contact_name)
        setStreet1(res?.data?.razorpayConfigs?.street1)
        setStreet2(res?.data?.razorpayConfigs?.street2)
        setCity(res?.data?.razorpayConfigs?.city)
        setState(res?.data?.razorpayConfigs?.state)
        setPostal_code(res?.data?.razorpayConfigs?.postal_code)
        setPan(res?.data?.razorpayConfigs?.pan)
        setGst(res?.data?.razorpayConfigs?.gst)
        setLoginEmail(res?.data?.loginEmail)

        if (res?.data?.razorpayConfigs) {
          let razorpayConfigs = res?.data?.razorpayConfigs

          setRazorpayConfig(res?.data?.razorpayConfigs)

          setRazorpayConfig(res?.data?.razorpayConfigs)


          try {
            if (razorpayConfigs?.errorFromRazorPay) {
              setRazorpayError(JSON.stringify(razorpayConfigs?.errorFromRazorPay, null, 2))
            }
          } catch (error) {
            console.error(error)
          }

          if (res?.data?.razorpayConfigs.manuallyEnabled) {
            setManuallyEnabledFromServer(true)
            setManuallyEnabled(true)
          }
          else {
            setManuallyEnabledFromServer(false)
            setManuallyEnabled(false)
          }

          if (razorpayConfigs.accountId) {
            setAccountId(razorpayConfigs.accountId)
          }

          setLinked_account_details__email(razorpayConfigs.linkedAccountDetails.email)
          setLinked_account_details__phone(razorpayConfigs.linkedAccountDetails.phone)
          setLinked_account_details__type(razorpayConfigs.linkedAccountDetails.type)
          setLinked_account_details__reference_id(razorpayConfigs.linkedAccountDetails.reference_id)
          setLinked_account_details__legal_business_name(razorpayConfigs.linkedAccountDetails.legal_business_name)
          setLinked_account_details__business_type(razorpayConfigs.linkedAccountDetails.business_type)
          setLinked_account_details__contact_name(razorpayConfigs.linkedAccountDetails.contact_name)
          setLinked_account_details__profile__category(razorpayConfigs.linkedAccountDetails.profile.category)
          setLinked_account_details__profile__subcategory(razorpayConfigs.linkedAccountDetails.profile.subcategory)
          setLinked_account_details__profile__addresses__registered__street1(razorpayConfigs.linkedAccountDetails.profile.addresses.registered.street1)
          setLinked_account_details__profile__addresses__registered__street2(razorpayConfigs.linkedAccountDetails.profile.addresses.registered.street2)
          setLinked_account_details__profile__addresses__registered__city(razorpayConfigs.linkedAccountDetails.profile.addresses.registered.city)
          setLinked_account_details__profile__addresses__registered__state(razorpayConfigs.linkedAccountDetails.profile.addresses.registered.state)
          setLinked_account_details__profile__addresses__registered__postal_code(razorpayConfigs.linkedAccountDetails.profile.addresses.registered.postal_code)
          setLinked_account_details__profile__addresses__registered__country(razorpayConfigs.linkedAccountDetails.profile.addresses.registered.country)
          setLinked_account_details__legal_info__pan(razorpayConfigs.linkedAccountDetails.legal_info.pan)
          setLinked_account_details__legal_info__gst(razorpayConfigs.linkedAccountDetails.legal_info.gst)
          setStake_holder__name(razorpayConfigs.stakeHolderDetails.name)
          setStake_holder__email(razorpayConfigs.stakeHolderDetails.email)
          setStake_holder__addresses__residential__street(razorpayConfigs.stakeHolderDetails.addresses.residential.street)
          setStake_holder__addresses__residential__city(razorpayConfigs.stakeHolderDetails.addresses.residential.city)
          setStake_holder__addresses__residential__state(razorpayConfigs.stakeHolderDetails.addresses.residential.state)
          setStake_holder__addresses__residential__postal_code(razorpayConfigs.stakeHolderDetails.addresses.residential.postal_code)
          setStake_holder__addresses__residential__country(razorpayConfigs.stakeHolderDetails.addresses.residential.country)
          setStake_holder__kyc__pan(razorpayConfigs.stakeHolderDetails.kyc.pan)
          setProduct_configuration__settlements__account_number(razorpayConfigs.productConfiguation.settlements.account_number)
          setProduct_configuration__settlements__ifsc_code(razorpayConfigs.productConfiguation.settlements.ifsc_code)
          setProduct_configuration__settlements__beneficiary_name(razorpayConfigs.productConfiguation.settlements.beneficiary_name)
          setProduct_configuration__tnc_accepted(razorpayConfigs.productConfiguation.tnc_accepted)

          setLinked_account_details__email_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.email)
          setLinked_account_details__phone_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.phone)
          setLinked_account_details__type_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.type)
          setLinked_account_details__reference_id_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.reference_id)
          setLinked_account_details__legal_business_name_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.legal_business_name)
          setLinked_account_details__business_type_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.business_type)
          setLinked_account_details__contact_name_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.contact_name)
          setLinked_account_details__profile__category_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.profile.category)
          setLinked_account_details__profile__subcategory_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.profile.subcategory)
          setLinked_account_details__profile__addresses__registered__street1_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.profile.addresses.registered.street1)
          setLinked_account_details__profile__addresses__registered__street2_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.profile.addresses.registered.street2)
          setLinked_account_details__profile__addresses__registered__city_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.profile.addresses.registered.city)
          setLinked_account_details__profile__addresses__registered__state_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.profile.addresses.registered.state)
          setLinked_account_details__profile__addresses__registered__postal_code_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.profile.addresses.registered.postal_code)
          setLinked_account_details__profile__addresses__registered__country_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.profile.addresses.registered.country)
          setLinked_account_details__legal_info__pan_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.legal_info.pan)
          setLinked_account_details__legal_info__gst_disabled(razorpayConfigs.detailsValidity.linkedAccountDetails.legal_info.gst)
          setStake_holder__name_disabled(razorpayConfigs.detailsValidity.stakeHolderDetails.name)
          setStake_holder__email_disabled(razorpayConfigs.detailsValidity.stakeHolderDetails.email)
          setStake_holder__addresses__residential__street_disabled(razorpayConfigs.detailsValidity.stakeHolderDetails.addresses.residential.street)
          setStake_holder__addresses__residential__city_disabled(razorpayConfigs.detailsValidity.stakeHolderDetails.addresses.residential.city)
          setStake_holder__addresses__residential__state_disabled(razorpayConfigs.detailsValidity.stakeHolderDetails.addresses.residential.state)
          setStake_holder__addresses__residential__postal_code_disabled(razorpayConfigs.detailsValidity.stakeHolderDetails.addresses.residential.postal_code)
          setStake_holder__addresses__residential__country_disabled(razorpayConfigs.detailsValidity.stakeHolderDetails.addresses.residential.country)
          setStake_holder__kyc__pan_disabled(razorpayConfigs.detailsValidity.stakeHolderDetails.kyc.pan)
          setProduct_configuration__settlements__account_number_disabled(razorpayConfigs.detailsValidity.productConfiguation.settlements.account_number)
          setProduct_configuration__settlements__ifsc_code_disabled(razorpayConfigs.detailsValidity.productConfiguation.settlements.ifsc_code)
          setProduct_configuration__settlements__beneficiary_name_disabled(razorpayConfigs.detailsValidity.productConfiguation.settlements.beneficiary_name)
          setProduct_configuration__tnc_accepted_disabled(razorpayConfigs.detailsValidity.productConfiguation.tnc_accepted)

        }

      }
    } catch (err) {
      toastError(err);
    }
    setLoading(false)
  };

  const handleAddRestaurant = async () => {
    try {
      let obj = {
        restaurantId: restaurantID,
        razorpayConfigs: {

          ...razorpayConfig,
          manuallyEnabled,
          accountId: manuallyEnabled ? accountId : razorpayConfig?.accountId,
          linkedAccountDetails: {
            ...razorpayConfig?.linkedAccountDetails,
            email: linked_account_details__email,
            phone: linked_account_details__phone,
            type: linked_account_details__type,
            reference_id: linked_account_details__reference_id, // this will ne _id of the user
            legal_business_name: linked_account_details__legal_business_name,
            business_type: linked_account_details__business_type,
            contact_name: linked_account_details__contact_name,
            profile: {
              ...razorpayConfig?.linkedAccountDetails?.profile,
              category: linked_account_details__profile__category,
              subcategory: linked_account_details__profile__subcategory,
              addresses: {
                registered: {
                  ...razorpayConfig?.linkedAccountDetails?.profile?.addresses?.registered,
                  street1: linked_account_details__profile__addresses__registered__street1,
                  street2: linked_account_details__profile__addresses__registered__street2,
                  city: linked_account_details__profile__addresses__registered__city,
                  state: linked_account_details__profile__addresses__registered__state,
                  postal_code: linked_account_details__profile__addresses__registered__postal_code,
                  country: linked_account_details__profile__addresses__registered__country,
                }
              }
            },
            legal_info: {
              ...razorpayConfig?.linkedAccountDetails?.legal_info,
              pan: linked_account_details__legal_info__pan,
              gst: linked_account_details__legal_info__gst,
            }
          },
          stakeHolderDetails: {
            ...razorpayConfig?.stakeHolderDetails,
            name: stake_holder__name,
            email: stake_holder__email,
            addresses: {
              residential: {
                ...razorpayConfig?.stakeHolderDetails?.addresses?.residential,
                street: stake_holder__addresses__residential__street,
                city: stake_holder__addresses__residential__city,
                state: stake_holder__addresses__residential__state,
                postal_code: stake_holder__addresses__residential__postal_code,
                country: stake_holder__addresses__residential__country,
              }
            },
            kyc: {
              ...razorpayConfig?.stakeHolderDetails?.kyc,
              pan: stake_holder__kyc__pan,
            },
            // notes: {
            //   random_key_by_partner: String,
            // }
          },
          productConfiguation: {
            ...razorpayConfig?.productConfiguation,
            settlements: {
              ...razorpayConfig?.productConfiguation?.settlements,
              account_number: product_configuration__settlements__account_number,
              ifsc_code: product_configuration__settlements__ifsc_code,
              beneficiary_name: product_configuration__settlements__beneficiary_name,
            },
            tnc_accepted: product_configuration__tnc_accepted
          },
        },
        loginEmail,
        password,
      };
      let res

      if (id) {
        if (password == "") {
          delete obj.password
        }
        res = await updateRestaurantById(obj, id);
      }
      else {
        res = await addRestaurent(obj);
      }
      if (res.data.message) {
        toastSuccess(res.data.message);
        // handleGetRestaurantsById(restaurantID);
        // setRestaurantObj(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (id && id != "") {
      handleGetRestaurantsById();
    }
  }, [id]);



  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <DashboardBox>
            <div className="row">
              <h3 className="blue-1 mt-3">Petpuja Restaurant Id</h3>
              <div className="col-6 mt-3">
                <label htmlFor="">Restaurant PetPuja Id (Menu Sharing code)</label>
                <input type="text" placeholder="Please enter PetPuja Id (Menu Sharing code)" onChange={(e) => setRestaurantID(e.target.value)} value={restaurantID} className="form-control" />
              </div>
              <div className="col-6 mt-3">
              </div>

              <h3 className="blue-1 mt-3">Restaurant Credentials</h3>
              <div className="col-6 mt-3">
                <label htmlFor="">Email</label>
                <input type="text" placeholder="Please enter Login Email" onChange={(e) => setLoginEmail(e.target.value)} value={loginEmail} className="form-control" />
              </div>
              <div className="col-6 mt-3">
                <label htmlFor="">Password</label>
                <input type="text" placeholder="Please enter Password" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" />
              </div>

              <div className="mb-4">

                <h3 className="blue-1 mt-5">Configuration For Razorpay repayments</h3>
                {
                  !manuallyEnabledFromServer &&
                  <CustomButton btnName={"Manually Configure Razorpay"} ClickEvent={() => setManuallyEnabled(prev => !prev)} extraClass={"me-3"} isBtn iconName="fa-solid fa-pencil" />
                }

              </div>
              {
                manuallyEnabled ? <>
                  <div className="form-group">
                    <label htmlFor="">Account Id : </label>
                    <input type="text" name="accountId" className="form-control" value={accountId} onChange={(e) => setAccountId(e.target.value)} />
                  </div>
                </>
                  :
                  <>




                    {
                      razorpayError &&
                      <div className="col-12">
                        <pre>{razorpayError}</pre>
                      </div>
                    }
                    <div className="col-12">
                      <h5>
                        Linked Account Details:
                      </h5>
                    </div>

                    {
                      id &&
                      <>
                        <div className="col-12 mt-3">
                          <h6>Details:</h6>
                        </div>
                        <div className="col-12 mt-3">
                          reference_id : {razorpayConfig?.linkedAccountDetails?.reference_id}
                        </div>
                        <div className="col-12 mt-3">
                          account_id : {razorpayConfig?.accountId}
                        </div>
                        <div className="col-12 mt-3">
                          stakeholder_id : {razorpayConfig?.stakeHolderId}
                        </div>
                        <div className="col-12 mt-3">
                          Product Configuration: {razorpayConfig?.productConfigurationId}
                        </div>
                        <div className="col-12 mt-3">
                          Activation Status: {razorpayConfig?.activation_status}
                        </div>
                      </>
                    }
                    <div className="col-6 mt-3">
                      <label htmlFor="">Email</label>
                      <input type="text" placeholder="Please enter Email" disabled={linked_account_details__email_disabled} onChange={(e) => setLinked_account_details__email(e.target.value)} value={linked_account_details__email} className="form-control" />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Phone</label>
                      <input type="text" placeholder="Please enter Phone number" disabled={linked_account_details__phone_disabled} onChange={(e) => setLinked_account_details__phone(e.target.value)} value={linked_account_details__phone} className="form-control" />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Legal Business Name</label>
                      <input type="text" placeholder="Please enter Legal Business Name" disabled={linked_account_details__legal_business_name_disabled} onChange={(e) => setLinked_account_details__legal_business_name(e.target.value)} value={linked_account_details__legal_business_name} className="form-control" />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Business Type</label>
                      <select name="" id="" className="form-control"
                        disabled={linked_account_details__business_type_disabled}
                        value={linked_account_details__business_type}
                        onChange={(e) => setLinked_account_details__business_type(e.target.value)}
                      >
                        <option value="" disabled>Please Select A Business Type</option>
                        {Object.keys(razorPayBusinessType).map(el => <option value={el}>{el}</option>)}
                      </select>
                    </div>

                    <div className="col-6 mt-3">
                      <label htmlFor="">Category</label>
                      <select name="" id="" className="form-control"
                        disabled={linked_account_details__profile__subcategory_disabled}
                        value={linked_account_details__profile__subcategory}
                        onChange={(e) => setLinked_account_details__profile__subcategory(e.target.value)}
                      >
                        <option value="" disabled>Please Select A category</option>
                        {Object.keys(razorPaySubCategory).map(el => <option value={el}>{el} (food)</option>)}
                      </select>
                    </div>

                    <h6 className="mt-5">
                      Registered Address :
                    </h6>

                    <div className="col-6 mt-3">
                      <label htmlFor="">Street 1</label>
                      <input
                        type="text"
                        placeholder="Please enter Street 1"
                        disabled={linked_account_details__profile__addresses__registered__street1_disabled}
                        onChange={(e) => setLinked_account_details__profile__addresses__registered__street1(e.target.value)}
                        value={linked_account_details__profile__addresses__registered__street1}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Street 2</label>
                      <input
                        type="text"
                        placeholder="Please enter Street 2"
                        disabled={linked_account_details__profile__addresses__registered__street2_disabled}
                        onChange={(e) => setLinked_account_details__profile__addresses__registered__street2(e.target.value)}
                        value={linked_account_details__profile__addresses__registered__street2}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">City</label>
                      <input
                        type="text"
                        placeholder="Please enter City"
                        disabled={linked_account_details__profile__addresses__registered__city_disabled}
                        onChange={(e) => setLinked_account_details__profile__addresses__registered__city(e.target.value)}
                        value={linked_account_details__profile__addresses__registered__city}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">State</label>
                      <input
                        type="text"
                        placeholder="Please enter State"
                        disabled={linked_account_details__profile__addresses__registered__state_disabled}
                        onChange={(e) => setLinked_account_details__profile__addresses__registered__state(e.target.value)}
                        value={linked_account_details__profile__addresses__registered__state}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Postal Code</label>
                      <input
                        type="text"
                        placeholder="Please enter Postal Code"
                        disabled={linked_account_details__profile__addresses__registered__postal_code_disabled}
                        onChange={(e) => setLinked_account_details__profile__addresses__registered__postal_code(e.target.value)}
                        value={linked_account_details__profile__addresses__registered__postal_code}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Country</label>
                      <input
                        type="text"
                        placeholder="Please enter Country"
                        disabled={linked_account_details__profile__addresses__registered__country_disabled}
                        onChange={(e) => setLinked_account_details__profile__addresses__registered__country(e.target.value)}
                        value={linked_account_details__profile__addresses__registered__country}
                        className="form-control"
                      />
                    </div>
                    <h6 className="mt-2">Legal Info:</h6>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Pan Number</label>
                      <input
                        type="text"
                        placeholder="Please enter Pan Number"
                        disabled={linked_account_details__legal_info__pan_disabled}
                        onChange={(e) => setLinked_account_details__legal_info__pan(e.target.value)}
                        value={linked_account_details__legal_info__pan}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Gst</label>
                      <input
                        type="text"
                        placeholder="Please enter Gst"
                        disabled={linked_account_details__legal_info__gst_disabled}
                        onChange={(e) => setLinked_account_details__legal_info__gst(e.target.value)}
                        value={linked_account_details__legal_info__gst}
                        className="form-control"
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <h5>
                        Stakeholder Details:
                      </h5>
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Stateholder Name</label>
                      <input
                        type="text"
                        placeholder="Please enter Stateholder Name"
                        disabled={stake_holder__name_disabled}
                        onChange={(e) => setStake_holder__name(e.target.value)}
                        value={stake_holder__name}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Stakeholder Email</label>
                      <input
                        type="text"
                        placeholder="Please enter Stakeholder Email"
                        disabled={stake_holder__email_disabled}
                        onChange={(e) => setStake_holder__email(e.target.value)}
                        value={stake_holder__email}
                        className="form-control"
                      />
                    </div>
                    <h6 className="mt-5">Stake Holder Address</h6>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Street</label>
                      <input
                        type="text"
                        placeholder="Please enter Street"
                        disabled={stake_holder__addresses__residential__street_disabled}
                        onChange={(e) => setStake_holder__addresses__residential__street(e.target.value)}
                        value={stake_holder__addresses__residential__street}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">City</label>
                      <input
                        type="text"
                        placeholder="Please enter City"
                        disabled={stake_holder__addresses__residential__city_disabled}
                        onChange={(e) => setStake_holder__addresses__residential__city(e.target.value)}
                        value={stake_holder__addresses__residential__city}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">State</label>
                      <input
                        type="text"
                        placeholder="Please enter State"
                        disabled={stake_holder__addresses__residential__state_disabled}
                        onChange={(e) => setStake_holder__addresses__residential__state(e.target.value)}
                        value={stake_holder__addresses__residential__state}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Postal Code</label>
                      <input
                        type="text"
                        placeholder="Please enter Postal Code"
                        disabled={stake_holder__addresses__residential__postal_code_disabled}
                        onChange={(e) => setStake_holder__addresses__residential__postal_code(e.target.value)}
                        value={stake_holder__addresses__residential__postal_code}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Country</label>
                      <input
                        type="text"
                        placeholder="Please enter Country"
                        disabled={stake_holder__addresses__residential__country_disabled}
                        onChange={(e) => setStake_holder__addresses__residential__country(e.target.value)}
                        value={stake_holder__addresses__residential__country}
                        className="form-control"
                      />

                      <small style={{ fontSize: "0.8em" }}>
                        The country: The minimum length is 2 and maximum length is 64. This can either be a country code in capital letters or the full name of the country in lower case letters. For example, for India, you must write either IN or india.
                      </small>


                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">kyc pan</label>
                      <input
                        type="text"
                        placeholder="Please enter kyc pan"
                        disabled={stake_holder__kyc__pan_disabled}
                        onChange={(e) => setStake_holder__kyc__pan(e.target.value)}
                        value={stake_holder__kyc__pan}
                        className="form-control"
                      />
                      <small style={{ fontSize: "0.8em" }}>
                        - This is a 10-digit alphanumeric code. For example, AVOPB1111K.
                      </small>
                      <br />
                      <small style={{ fontSize: "0.8em" }}>
                        - Validation for Stakeholder PAN: The 4th digit should be 'P'.
                      </small>

                    </div>
                    {/* <div className="col-6 mt-3">
                <label htmlFor="">notes</label>
                <input
                  type="text"
                  placeholder="Please enter notes"
                  disabled={stake_holder__notes__some_notes_disabled}
                  onChange={(e) => setStake_holder__notes__some_notes(e.target.value)}
                  value={stake_holder__notes__some_notes}
                  className="form-control"
                />
              </div> */}

                    <div className="col-12 mt-3">
                      <h5>
                        Bank Details:
                      </h5>
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Account Number</label>
                      <input
                        type="text"
                        placeholder="Please enter Account Number"
                        disabled={product_configuration__settlements__account_number_disabled}
                        onChange={(e) => setProduct_configuration__settlements__account_number(e.target.value)}
                        value={product_configuration__settlements__account_number}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">IFSC Code</label>
                      <input
                        type="text"
                        placeholder="Please enter IFSC Code"
                        disabled={product_configuration__settlements__ifsc_code_disabled}
                        onChange={(e) => setProduct_configuration__settlements__ifsc_code(e.target.value)}
                        value={product_configuration__settlements__ifsc_code}
                        className="form-control"
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="">Benificiary Name</label>
                      <input
                        type="text"
                        placeholder="Please enter Benificiary Name"
                        disabled={product_configuration__settlements__beneficiary_name_disabled}
                        onChange={(e) => setProduct_configuration__settlements__beneficiary_name(e.target.value)}
                        value={product_configuration__settlements__beneficiary_name}
                        className="form-control"
                      />
                    </div>










                  </>
              }

              {/* <div className="col-6 mt-3"></div> */}
              <div className="col-12 mt-4">
                <CustomButton btnName={"Submit"} ClickEvent={() => handleAddRestaurant()} extraClass={"me-3"} isBtn iconName="fa-solid fa-pencil" path="/Restaurants/Edit" />
                <CustomButton btnName={"Close"} ClickEvent={() => navigate(-1)} isBtn iconName="fa-solid fa-close" path="/Restaurants/Edit" />
              </div>
              {
                loading &&
                <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
                  <h1>Loading Please Wait....</h1>
                </div>
              }

            </div>
          </DashboardBox>
        </div>
      </div>
    </div>
  );
}
