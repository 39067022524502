import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getDinerData, getTotalOrders } from "../../services/index.service";
import { toastError } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";

function DinerData() {
    const [loading, setLoading] = useState(false);

    const [productArr, setProductsArr] = useState([]);
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const getSales = async () => {
        try {
            setLoading(true)

            let { data: res } = await getDinerData();
            if (res.data) {
                setLoading(false)
                console.log(res.data)
                setProductsArr(res.data);
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    };

    useEffect(() => {
        getSales()
    }, [])


    const handleSearch = async () => {
        try {
            setLoading(true)
            let query = `startDate=${startDate}&endDate=${endDate}`
            let { data: res } = await getDinerData(query);
            if (res.data) {
                console.log(res.data)
                setLoading(false)
                setProductsArr(res.data);
            }
        } catch (error) {
            setLoading(false)
            toastError(error)
        }
    }




    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "15%",
        },
        {
            name: "Invoice Id",
            selector: (row, index) => row?.order_id,
            sortable: true,
        },
        {
            name: "Customer Name",
            selector: (row, index) => row?.customer_name,
            sortable: true,
        },
        {
            name: "Customer Phone",
            selector: (row, index) => row?.customer_phone,
            sortable: true,
        },
        {
            name: "Table No",
            cell: (row) => <p>{row?.tableNo}</p>,
        },
        {
            name: "Restaurant name",
            cell: (row) => <p>{row?.restaurant_name}</p>,
        },
        {
            name: "Amount",
            selector: (row) => row?.amount,
            cell: (row) => <p>₹ {row?.amount}</p>,
            sortable: true

        },
        {
            name: "Date",
            selector: (row) => <p>{moment(row?.createdAt).format("DD-MM-YYYY")}</p>,
            width: "20%",
            sortable: true
        },
    ];




    return (
        <main>
            <div className="row mt-5 py-4">
                <div className="col-12">
                    <h2>Diner Data</h2>
                </div>

            </div>

            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="row  mb-4">
                                <div className="col-4">
                                    <label>Start Date</label>
                                    <input value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" placeholder="Start Date" type="date" />
                                </div>
                                <div className="col-4">
                                    <label>End Date</label>
                                    <input value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" placeholder="Start Date" type="date" />
                                </div>
                                <div className="col-4">
                                    <CustomButton isBtn iconName="fa-solid" extraClass={"mt-4"} btnName="Search" btntype="button" ClickEvent={() => handleSearch()} />
                                </div>

                            </div>
                            <DashboardTable>
                                <DataTable columns={product_sale_columns} data={productArr && productArr.length > 0 ? productArr : []} pagination />


                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
                    <h1>Loading Please Wait....</h1>
                </div>
            }
        </main >
    );
}

export default DinerData;
