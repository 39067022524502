import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { getRestaurantsMenuByTableById } from '../../services/restaurant.service';
import { toastError } from '../../utils/toastUtils';
import CustomButton from '../Utility/Button';
import { DashboardBox } from '../Utility/DashboardBox';

export default function MenuList() {
    const [loading, setLoading] = useState(false);

    const [restaurantObj, setRestaurantObj] = useState({});

    const [restaurantID, setRestaurantID] = useState("");
    const [tableId, setTableId] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();

    const handleGetRestaurantsById = async (id) => {
        try {
            setLoading(true)
            let { data: res } = await getRestaurantsMenuByTableById(id)
            if (res.data) {
                setLoading(false)
                setRestaurantObj(res.data);
            }
        }
        catch (err) {
            setLoading(false)
            toastError(err)
        }
    }
    // useEffect(() => {
    //     if (restaurantID) {
    //         handleGetRestaurantsById(restaurantID)
    //     }
    // }, [restaurantID])

    useEffect(() => {
        if (tableId) {
            handleGetRestaurantsById(tableId)
        }
    }, [tableId])


    useEffect(() => {
        if (searchParams.get("restaurantId")) {
            setRestaurantID(searchParams.get("restaurantId"))
        }
    }, [searchParams.get("restaurantId")])


    useEffect(() => {
        if (searchParams.get("tableId")) {
            setTableId(searchParams.get("tableId"))
        }
    }, [searchParams.get("tableId")])
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">

                    <DashboardBox>
                        <div className="row">

                            <div className="col-6">
                                <label htmlFor="">Menu Video</label>
                                {/* <FileUpload onFileChange={setVideo} /> */}
                            </div>
                            <div className="col-6">
                            </div>
                            <div className="col-12 mt-4">
                                <h4>Menu</h4>
                                <div className="row">
                                    {
                                        restaurantObj.items && restaurantObj.items.map((el, index) => {
                                            return (
                                                <div className="col-4 my-2" key={index}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {el?.itemname}
                                                        </div>
                                                        {/* <div className="col-6">
                                                            <CustomButton btnName={"View Table Menu"} isLink iconName="fa-solid fa-eye" path={`/Menu/View?tableId=${el?.table_no}&restaurantId=${restaurantID}`} />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                            <div className="col-6 mt-4">
                                <CustomButton btnName={"Update Restaurant"} isBtn iconName="fa-solid fa-pencil" path="/Restaurants/Edit" />
                            </div>
                        </div>
                    </DashboardBox>
                </div>
            </div>

            {
                loading &&
                <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
                    <h1>Loading Please Wait....</h1>
                </div>
            }
        </div>
    )
}
