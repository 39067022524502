import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/product";

export const addProduct = (formData) => {
  return axios.post(serverUrl + "/addProduct", formData);
};

export const getProducts = (query) => {
  return axios.get(`${serverUrl}/getProducts?${query}`);
};


export const getSingleProductByRestaurantTableAndProductId = (query) => {
  return axios.get(`${serverUrl}/getProductByIds?${query}`);
};

export const deleteProductById = (id) => {
  return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateProductById = (formData, id, restaurantId, tableId) => {
  return axios.patch(`${serverUrl}/updateById/${id}/${restaurantId}/${tableId}`, formData);
};

export const updateProductExcel = (formData) => {
  return axios.post(`${serverUrl}/BulkProductUpload`, formData);
};