import axios from 'axios'
export const axiosAuth = axios.create()

axiosAuth.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("RESTAURANT")
        if (typeof token != 'undefined' && token !== null) {
            config.headers['restaurant'] = `${token}`
        }
        return config
    },
    function (error) {

        return Promise.reject(error)
    }
)

axiosAuth.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {

        return Promise.reject(error)
    }
)

export default axiosAuth
