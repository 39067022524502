import axios from "axios";
import { url } from "./url.service";

const serverUrl = url;



export const getTotalSales = (query) => {
    return axios.get(`${serverUrl}/getTotalSales?${query}`);
};

export const getTotalOrders = (query) => {
    return axios.get(`${serverUrl}/getTotalOrders?${query}`);
};

export const getPaymentCurrentStatusFromRazorpay = (id) => {
    return axios.get(`${serverUrl}/getPaymentCurrentStatusFromRazorpay/${id}`);
};

export const getTrendingProducts = (query) => {
    return axios.get(`${serverUrl}/getTrendingProducts?${query}`);
};

export const getWatchedVideos = (query) => {
    return axios.get(`${serverUrl}/getWatchedVideos?${query}`);
};



export const getDinerData = (query) => {
    return axios.get(`${serverUrl}/getDinerData?${query}`);
};