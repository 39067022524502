import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";
import Switch from "react-switch";
import { deletemaincategoryById, getmaincategories, updatemaincategoryById } from "../../services/MainCategory.service";
import { deleteRecomendationById, getRecomendations } from "../../services/Recomendation.service";
import { getTableMenuById } from "../../services/Table.service";
import { updateCategoryById } from "../../services/category.service";
import { updateProductById, updateProductExcel } from "../../services/product.service";
import { url } from "../../services/url.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import { accessObj, rolesObj } from "../Utility/constants";
import { generateFilePath } from "../Utility/utils";
function ViewRecomendation() {
    const [loading, setLoading] = useState(false);
    const [mainCategoriesArr, setMainCategoriesArr] = useState([]);
    const authObj = useSelector(state => state.auth)
    const [productArr, setProductsArr] = useState([]);
    const [uploadFile, setUploadFile] = useState(null);
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();
    const [tabDisplayed, setTabDisplayed] = useState("RecomendationCategory");
    const activeBtnStyles = { marginRight: 10, cursor: "pointer", color: "white", backgroundColor: "black", display: "grid", placeItems: "center", fontSize: 20, padding: "10px 20px", borderRadius: 10 };
    const inActiveBtnStyles = { marginRight: 10, cursor: "pointer", border: "solid 1px grey", display: "grid", placeItems: "center", fontSize: 20, padding: "10px 20px", borderRadius: 10 };
    const [categoriesArr, setCategoriesArr] = useState([]);

    const [recomendationsArr, setrecomendationsArr] = useState([]);


    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleGetProductsByTableId = async (id) => {
        try {
            setLoading(true)
            let { data: res } = await getTableMenuById(id);
            if (res.data) {
                setLoading(false)
                setProductsArr(res.data.map((el, index) => ({ ...el, index: index + 1 })));
                setCategoriesArr(res.categoryArr.map((el, index) => ({ ...el, index: index + 1 })))
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    };

    const handleGetMainCategories = async (tableId, restaurantId) => {
        try {
            setLoading(true)
            let { data: res } = await getmaincategories(`restaurantId=${restaurantId}&tableId=${tableId}`);
            if (res.data) {
                setLoading(false)
                setMainCategoriesArr(res.data.map((el, index) => ({ ...el, index: index + 1 })));
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    };
    const handleGetRecomendations = async (tableId, restaurantId) => {
        try {
            setLoading(true)
            let { data: res } = await getRecomendations(`restaurantId=${restaurantId}&tableId=${tableId}&recomendationcategory=${searchParams.get("recomendationcategoryId")}`);
            if (res.data) {
                setLoading(false)
                setrecomendationsArr(res.data.map((el, index) => ({ ...el, index: index + 1 })));
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    };

    const handleProductImageAdd = async (e, itemid) => {
        try {
            setLoading(true)
            let obj = {
                item_image_url: e,
            };
            let { data: res } = await updateProductById(obj, itemid, searchParams.get("restaurantId"), searchParams.get('tableId'));
            if (res.message) {
                setLoading(false)
                toastSuccess(res.message);
                handleGetProductsByTableId(searchParams.get("tableId"))
            }
        } catch (err) {
            toastError(err);
            setLoading(false)
        }
    };

    const handleProductVideoAdd = async (e, itemid) => {
        try {
            setLoading(true)
            let obj = {
                video: e,
            };
            let { data: res } = await updateProductById(obj, itemid, searchParams.get("restaurantId"), searchParams.get('tableId'));
            if (res.message) {
                toastSuccess(res.message);
                handleGetProductsByTableId(searchParams.get("tableId"))
                setLoading(false)
            }
        } catch (err) {
            toastError(err);
            setLoading(false)
        }
    };

    const checkDisplayPermissions = (checkPermission) => {
        if (authObj?.role == rolesObj.SUBADMIN) {
            let tempArr = authObj?.user?.accessArr;
            if (tempArr.some(el => el?.value == checkPermission)) {
                return true
            }
            else {
                return false
            }
        }
        else if (authObj?.role == rolesObj?.ADMIN) {
            return true
        }
    }



    useEffect(() => {
        if (searchParams.get("tableId")) {
            handleGetRecomendations(searchParams.get("tableId"), searchParams.get("restaurantId"))
            handleGetProductsByTableId(searchParams.get("tableId"))
            handleGetMainCategories(searchParams.get("tableId"), searchParams.get("restaurantId"))
        }
    }, [searchParams.get("tableId")]);

    const product_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => row.index,
            sortable: true,
            width: "15%",
        },
        {
            name: "Name",
            cell: (row) => <p>{row?.itemname}</p>,
            width: "20%",
        },
        {
            name: "Image",
            //
            cell: (row) => (
                <p>
                    {row?.item_image_url ? (
                        <>
                            <a href={generateFilePath(row.item_image_url)} target="_blank">
                                <img style={{ height: 100 }} src={generateFilePath(row.item_image_url)} alt="" />
                            </a>
                            {checkDisplayPermissions(accessObj?.add_images) &&
                                <div className="my-4">
                                    <FileUpload onFileChange={(e) => handleProductImageAdd(e, row.itemid)} />
                                </div>
                            }
                        </>
                    ) : (
                        <>
                            {checkDisplayPermissions(accessObj?.add_images) &&
                                <FileUpload onFileChange={(e) => handleProductImageAdd(e, row.itemid)} />
                            }
                        </>
                    )}{" "}
                </p>
            ),
            width: "20%",
        },
        {
            name: "Video",
            //
            cell: (row) => (
                <p>
                    {row?.video ? (
                        <>
                            <a href={generateFilePath(row.video)} target="_blank">
                                <video src={generateFilePath(row.video)} style={{ height: 100 }} controls></video>
                                <img style={{ height: 100 }} src={generateFilePath(row.video)} alt="" />
                            </a>
                            <div className="my-4">
                                {checkDisplayPermissions(accessObj?.add_videos) &&
                                    <FileUpload accepts="video/mp4,video/x-m4v,video/*" onFileChange={(e) => handleProductVideoAdd(e, row.itemid)} />
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            {checkDisplayPermissions(accessObj?.add_videos) &&
                                <FileUpload accepts="video/mp4,video/x-m4v,video/*" onFileChange={(e) => handleProductVideoAdd(e, row.itemid)} />
                            }
                        </>
                    )}{" "}
                </p>
            ),
            width: "20%",
        },
        {
            name: "Action",
            width: "15%",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row.id}
                        edit
                        isRedirected={true}
                        editPath={`/Product/Edit?productId=${row.itemid}&restaurantId=${row.petPujaRestaurantId}&tableId=${row.petPujaTableId}`}
                    />
                </>
            ),
        },
    ];



    const handleDeleteRecomendations = async (value) => {
        try {
            setLoading(true)


            let { data: res } = await deleteRecomendationById(value);
            if (res.message) {
                setLoading(false)
                toastSuccess(res.message);
                handleGetRecomendations(searchParams.get("tableId"), searchParams.get("restaurantId"))
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    }
    const handleDeleteMainCategory = async (value) => {
        try {
            setLoading(true)


            let { data: res } = await deletemaincategoryById(value);
            if (res.message) {
                setLoading(false)
                toastSuccess(res.message);
                handleGetMainCategories(searchParams.get("tableId"), searchParams.get("restaurantId"))
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    }
    const handleChangeBeverageTypeForMainCategory = async (value, itemid) => {
        try {
            setLoading(true)

            // alert("ASd")
            // console.log(value, "value")
            let obj = {
                isBeverage: value,
            };
            let { data: res } = await updatemaincategoryById(obj, itemid);
            if (res.message) {
                setLoading(false)
                toastSuccess(res.message);
                handleGetMainCategories(searchParams.get("tableId"), searchParams.get("restaurantId"))
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    }
    const handleChangeBeverageType = async (value, itemid) => {
        try {
            setLoading(true)

            // alert("ASd")
            // console.log(value, "value")
            let obj = {
                isBeverage: value,
            };
            let { data: res } = await updateCategoryById(obj, itemid);
            if (res.message) {
                setLoading(false)
                toastSuccess(res.message);
                handleGetProductsByTableId(searchParams.get("tableId"))
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    }
    const handleCategoryImageAdd = async (e, itemid) => {
        try {
            setLoading(true)
            // alert("ASd")
            let obj = {
                category_image_url: e,
            };
            let { data: res } = await updateCategoryById(obj, itemid);
            if (res.message) {
                toastSuccess(res.message);
                setLoading(false)
                handleGetProductsByTableId(searchParams.get("tableId"))

                // handleGetRestaurantsById(restaurantID);
                // setRestaurantObj(res.data);
            }
        } catch (err) {
            toastError(err);
            setLoading(false)
        }
    };



    const main_categories_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => row.index,
            sortable: true,
            width: "15%",
        },
        {
            name: "Name",
            cell: (row) => <p>{row?.name}</p>,
            width: "20%",
        },
        {
            name: "Categories",
            cell: (row) => (
                <p>
                    {row?.categoryArr && row?.categoryArr.length > 0 && row?.categoryArr.map((el, index) => {
                        return (
                            <div className="my-4">
                                {el.categoryname}
                            </div>
                        )
                    })}{" "}
                </p>
            ),
            width: "20%",
        },
        {
            name: "Is Beverage",
            cell: (row) => <>
                <Switch onChange={(e) => handleChangeBeverageTypeForMainCategory(e, row._id)} checked={row.isBeverage} />
                <div style={{ marginLeft: 30 }}>{row.isBeverage ? "Beverage" : "Not beverage"}</div>
            </>,
            width: "20%",
        },
        {
            name: "Action",
            width: "15%",
            cell: (row) => (
                <>
                    <ActionIcon
                        Uniquekey={row.id}
                        edit
                        isRedirected={true}
                        editPath={`/Main-Category/Edit?restaurantId=${row.petPujaRestaurantId}&tableId=${row.petPujaTableId}&MainCategoryId=${row._id}`}
                    />
                    <span className="ms-3">
                        <ActionIcon
                            Uniquekey={row.id}
                            remove
                            isRedirected={true}
                            onDeleteClick={() => handleDeleteMainCategory(row._id)}
                            deletePath={`/Product/View?${searchParams.toString()}`}
                        />
                    </span>
                </>
            ),
        },
    ];




    const recomendations_columns = [
        {
            name: "SL",
            selector: (row, index) => row.index,
            sortable: true,
            width: "15%",
        },
        {
            name: "Products",
            cell: (row) => <p>
                {row?.productsArr.reduce((acc, el, index) => acc + ` ${el.itemname} ${el.name ? `(${el.name})` : ""} ${(row?.productsArr?.length - 1) != index ? " , " : ""}`, "")}
            </p>,
            width: "20%",
        },
        {
            name: "Total Price",
            cell: (row) => (
                <p>
                    {row.price}
                </p>
            ),
            width: "20%",
        },
        {
            name: "Action",
            width: "15%",
            cell: (row) => (
                <>
                    {/* <ActionIcon
                        Uniquekey={row.id}
                        edit
                        isRedirected={true}
                        editPath={`/Recomendations/Edit?restaurantId=${row.petPujaRestaurantId}&tableId=${row.petPujaTableId}&recomedationId=${row._id}`}
                    /> */}
                    <span className="ms-3">
                        <ActionIcon
                            Uniquekey={row.id}
                            remove
                            isRedirected={true}
                            onDeleteClick={() => handleDeleteRecomendations(row._id)}
                            deletePath={`/ViewRecomendation?${searchParams.toString()}`}
                        />
                    </span>
                </>
            ),
        },
    ];

    // const recomendations_columns = [
    //     {
    //         name: "SL",
    //         selector: (row, index) => row.index,
    //         sortable: true,
    //         width: "15%",
    //     },
    //     {
    //         name: "Name",
    //         cell: (row) => <p>
    //             {row?.name}
    //         </p>,
    //         width: "20%",
    //     },
    //     {
    //         name: "Is Combo",
    //         cell: (row) => (
    //             <p>
    //                 {row.isCombo ? "Yes" : "No"}
    //             </p>
    //         ),
    //         width: "20%",
    //     },
    //     {
    //         name: "Recomendation",
    //         cell: (row) => (
    //             <>
    //                 <Button onClick={() => navigate(`/AddRecomendationCategory?tableId=${searchParams.tableId}&tableNo=${searchParams.tableNo}&restaurantId=${searchParams.restaurantId}&recomendationcategoryId=${row?._id}`)}>View</Button>
    //                 <Button onClick={() => navigate(`/AddRecomendationCategory?tableId=${searchParams.tableId}&tableNo=${searchParams.tableNo}&restaurantId=${searchParams.restaurantId}&recomendationcategoryId=${row?._id}`)}>Add</Button>

    //             </>
    //         ),
    //         width: "20%",
    //     },
    //     {
    //         name: "Action",
    //         width: "15%",
    //         cell: (row) => (
    //             <>
    //                 <ActionIcon
    //                     Uniquekey={row.id}
    //                     edit
    //                     isRedirected={true}
    //                     editPath={`/Recomendations/Edit?restaurantId=${row.petPujaRestaurantId}&tableId=${row.petPujaTableId}&recomedationId=${row._id}`}
    //                 />
    //                 <span className="ms-3">
    //                     <ActionIcon
    //                         Uniquekey={row.id}
    //                         remove
    //                         isRedirected={true}
    //                         onDeleteClick={() => handleDeleteRecomendations(row._id)}
    //                         deletePath={`/Product/View?${searchParams.toString()}`}
    //                     />
    //                 </span>
    //             </>
    //         ),
    //     },
    // ];

    const category_sale_columns = [
        {
            name: "SL",
            selector: (row, index) => row.index,
            sortable: true,
            width: "15%",
        },
        {
            name: "Name",
            cell: (row) => <p>{row?.categoryname}</p>,
            width: "20%",
        },
        {
            name: "Image",
            cell: (row) => (
                <p>
                    {row?.category_image_url ? (
                        <>
                            <a href={generateFilePath(row.category_image_url)} target="_blank">
                                <img style={{ height: 100 }} src={generateFilePath(row.category_image_url)} alt="" />
                            </a>
                            <div className="my-4">
                                {checkDisplayPermissions(accessObj?.add_images) &&
                                    <FileUpload onFileChange={(e) => handleCategoryImageAdd(e, row._id)} />
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            {checkDisplayPermissions(accessObj?.add_images) &&
                                <FileUpload onFileChange={(e) => handleCategoryImageAdd(e, row._id)} />
                            }
                        </>
                    )}{" "}
                </p>
            ),
            width: "20%",
        },
        {
            name: "Is Beverage",
            cell: (row) => <>
                <Switch onChange={(e) => handleChangeBeverageType(e, row._id)} checked={row.isBeverage} />
                <div style={{ marginLeft: 30 }}>{row.isBeverage ? "Beverage" : "Not beverage"}</div>
            </>,
            width: "20%",
        },
    ];

    const handleDownloadExcel = () => {
        window.open(`${url}/product/downloadProductExcel?restaurantId=${searchParams.get("restaurantId")}&tableId=${searchParams.get('tableId')}`)
    }

    const handleUploadExcel = async () => {
        try {
            setLoading(true)
            let formdata = new FormData();
            formdata.append("excel", uploadFile);
            let { data: res } = await updateProductExcel(formdata);
            if (res) {
                // console.log(res)
                setLoading(false)
                toastSuccess(res.message)
            }
        } catch (error) {
            setLoading(false)
            toastError(error)
        }
    }


    return (
        <main>


            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="row d-flex align-items-center justify-content-between mb-4">
                                <div className="col-12">
                                    <h5 className="blue-1">{tabDisplayed == "Menu" ? "Product List" : tabDisplayed == "Main Category" ? "Main Category" : tabDisplayed == "Excel" ? "Excel" : "Category List"}</h5>
                                </div>
                            </div>
                            <DashboardTable>
                                {
                                    tabDisplayed == "Menu" ?
                                        <Products product_sale_columns={product_sale_columns} productArr={productArr} />
                                        :
                                        tabDisplayed == 'Main Category' ?
                                            <MainCategories main_categories_sale_columns={main_categories_sale_columns} mainCategoriesArr={mainCategoriesArr} inActiveBtnStyles={inActiveBtnStyles} navigate={navigate} searchParams={{ tableId: searchParams.get("tableId"), restaurantId: searchParams.get("restaurantId") }} />
                                            :
                                            tabDisplayed == 'RecomendationCategory' ?
                                                <Recomendations recomendations_columns={recomendations_columns} recomendationsArr={recomendationsArr} inActiveBtnStyles={inActiveBtnStyles} navigate={navigate} searchParams={{ tableId: searchParams.get("tableId"), restaurantId: searchParams.get("restaurantId"), tableNo: searchParams.get("tableNo"), recomendationcategoryId: searchParams.get("recomendationcategoryId") }} />
                                                :
                                                <Categories product_sale_columns={category_sale_columns} productArr={categoriesArr} />
                                    // <DataTable columns={category_sale_columns} data={categoriesArr && categoriesArr.length > 0 ? categoriesArr : []} pagination />
                                }

                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Excel Management"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-12">
                                    <div className="row">
                                        <CustomButton isBtn iconName="fa-solid" extraClass={"mb-4"} noIcon={true} btnName="Download Product Excel" btntype="button" ClickEvent={() => handleDownloadExcel()} />
                                        <hr />
                                        <div className="col-12 mt-5">
                                            <div className="row d-flex">
                                                <input className="form-control" type="file" onChange={(e) => setUploadFile(e.target.files[0])} />
                                                <CustomButton noIcon={true} isBtn iconName="fa-solid" extraClass={"mt-4"} btnName="Upload Product Excel" btntype="button" ClickEvent={() => handleUploadExcel()} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            {
                loading &&
                <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
                    <h1>Loading Please Wait....</h1>
                </div>
            }
        </main >
    );
}

export default ViewRecomendation;




function MainCategories({ main_categories_sale_columns, mainCategoriesArr, inActiveBtnStyles, navigate, searchParams }) {
    return (
        <div className="row d-flex justify-content-end">
            <div className="col-3">
                <div style={inActiveBtnStyles} onClick={() => navigate(`/Main-Category/Add?tableId=${searchParams.tableId}&restaurantId=${searchParams.restaurantId}`)}>
                    Add Main Category
                </div>
            </div>
            <DataTable columns={main_categories_sale_columns} data={mainCategoriesArr && mainCategoriesArr.length > 0 ? mainCategoriesArr : []} pagination />
        </div>
    )
}



function Recomendations({ recomendations_columns, recomendationsArr, inActiveBtnStyles, navigate, searchParams }) {
    return (
        <div className="row d-flex justify-content-end">
            <div className="col-4">
                <div style={inActiveBtnStyles} onClick={() => navigate(`/Recomendations/Add?tableId=${searchParams.tableId}&tableNo=${searchParams.tableNo}&restaurantId=${searchParams.restaurantId}&recomendationcategoryId=${searchParams.recomendationcategoryId}`)}>
                    Add Recomendation
                </div>
            </div>
            <DataTable columns={recomendations_columns} data={recomendationsArr && recomendationsArr.length > 0 ? recomendationsArr : []} pagination />
        </div >
    )
}


function Products({ product_sale_columns, productArr }) {
    return (
        <DataTable columns={product_sale_columns} data={productArr && productArr.length > 0 ? productArr : []} pagination />
    )
}
function Categories({ product_sale_columns, productArr }) {
    return (
        <DataTable columns={product_sale_columns} data={productArr && productArr.length > 0 ? productArr : []} pagination />
    )
}

