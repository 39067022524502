import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
// import { userAdd } from "../../redux/actions/Users/users.actions";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import { accessArr, rolesObj } from "../Utility/constants";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addUser } from "../../services/users.service";
import { getById } from "../../services/users.service";
import { updateUser } from "../../services/users.service";
// import FileUpload from "../Utility/FileUpload";
// import { AccessArr, RolesArr, RolesObj } from "../Utility/utils";
function AddUsers() {
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const [name, setName] = useState("");
    const [shopName, setShopName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    // const [seletedRoleObj, setSeletedRoleObj] = useState({ label: roleObj.USER, value: RolesObj?.USER });
    const [selectedAccessArr, setSelectedAccessArr] = useState([]);
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isUpdate, setIsUpdate] = useState(false);
    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (isUpdate) {
                let obj = {
                    name: name,
                    email,
                    phone,
                    accessArr: selectedAccessArr,
                };
                const { data: res } = await updateUser(obj, searchParams.get("userId"));
                if (res) {
                    setLoading(false)
                    toastSuccess(res.message)
                    navigate(-1)
                }
            }
            else {

                let obj = {
                    name: name,
                    email,
                    phone,
                    password,
                    accessArr: selectedAccessArr,
                    role: rolesObj?.SUBADMIN
                };
                const { data: res } = await addUser(obj);
                if (res) {
                    setLoading(false)
                    toastSuccess(res.message)
                    navigate(-1)
                }
            }
        } catch (error) {
            toastError(error)
            setLoading(false)
        }

    };

    const getUser = async () => {
        if (searchParams.get("userId")) {
            setLoading(true)

            try {

                const { data: res } = await getById(searchParams.get("userId"));
                if (res) {
                    setLoading(false)
                    setName(res?.data?.name)
                    setPhone(res?.data?.phone)
                    setEmail(res?.data?.email)
                    setSelectedAccessArr(res?.data?.accessArr)
                    setIsUpdate(true)
                }
            } catch (error) {
                toastError(error)
                setLoading(false)
            }
        }
    }
    useEffect(() => {
        getUser()
    }, [])

    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <h5 className="blue-1 mb-4">Add User</h5>
                    <DashboardBox>
                        <form className="form row">
                            <h5 className="blue-1 mb-4">Basic Info</h5>
                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    Name <span className="red">*</span>
                                </label>
                                <input name="first_name" className="form-control" type="text" onChange={(e) => setName(e.target.value)} value={name} required="" />
                            </div>


                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    Mobile Number
                                    <span className="red">*</span>
                                </label>
                                <input name="last_name" className="form-control" type="number" maxLength={10} onChange={(e) => setPhone(`${e.target.value}`.length < 11 ? e.target.value : `${e.target.value}`.slice(0, 10))} value={phone} required="" />
                            </div>
                            <div className="col-12 col-md-4 mb-3">
                                <label>
                                    Email Address
                                    <span className="red">*</span>
                                </label>
                                <input name="text" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" type="email" />
                            </div>
                            {!isUpdate &&
                                <div className="col-12 col-md-4 mb-3">
                                    <label>
                                        Password
                                        <span className="red">*</span>
                                    </label>
                                    <input name="text" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" type="password" />
                                </div>
                            }


                            <div className="col-12  col-md-4 mb-3">
                                <label>Access</label>
                                <Select onChange={(e) => setSelectedAccessArr(e)} isMulti value={selectedAccessArr} options={accessArr} />
                            </div>

                            <div className="col-12 mt-2 text-center">
                                <CustomButton
                                    isBtn
                                    ClickEvent={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                    }}
                                    iconName="fa-solid fa-check"
                                    btnName="Submit"
                                />
                            </div>
                        </form>
                    </DashboardBox>
                </div>
                {
                    loading &&
                    <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
                        <h1>Loading Please Wait....</h1>
                    </div>
                }
            </section>
        </main>
    );
}

export default AddUsers;
