import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import Switch from "react-switch";
import { addRecomendation, getRecomendationById, updateRecomendationById } from "../../services/Recomendation.service";
import { getTableMenuById } from "../../services/Table.service";
import { getAddons } from "../../services/getAddons.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import { generateFilePath } from "../Utility/utils";
import { getRecomendationCategoryById } from "../../services/RecomendationCategory.service";

export default function AddRecomendations() {
    const navigate = useNavigate()


    const [addonsLoading, setAddonsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [image, setImage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isBeverage, setIsBeverage] = useState(false);
    const location = useLocation()
    const [categoryArr, setCategoryArr] = useState([]);
    const [productsArr, setProductsArr] = useState([]);
    const [mainProductsArr, setMainProductsArr] = useState([]);

    const [recomendationCategoryObj, setRecomendationCategoryObj] = useState(null);
    const [isVeg, setIsVeg] = useState(false);

    const handleSetProductPrice = (productObj) => {
        let price = 0


        if (productObj?.addonArr && productObj?.addonArr.length > 0) {
            price = parseInt(productObj.price) + productObj.addonArr.reduce((acc, el) => acc + parseInt(el.price), 0)
        }
        else {
            price = productObj.price
        }

        return price
    }

    const handleIfMinAddonsAreSelected = () => {
        let tempArr = selectedProducts
        return tempArr.some(el => !el?.addonArr || el?.addonArr?.length == 0 || el.addonsArr.some(elx => {
            if (elx.min_qty != el?.addonArr.filter(ell => ell.group_id == elx.id)?.length) {
                console.log(elx.min_qty, el?.addonArr.filter(ell => ell.group_id == elx.id)?.length, "min quantity check")
                return true
            }
            else {
                return false
            }
        }))

    }


    const getRecomendationCategoryObj = async () => {
        try {
            console.log("getign")
            const { data: res } = await getRecomendationCategoryById(searchParams.get("recomendationcategoryId"));
            if (res) {
                console.log(res.data)
                setRecomendationCategoryObj(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }


    const handleAdd = async () => {
        try {
            if (recomendationCategoryObj?.isCombo) {
                if (selectedProducts && selectedProducts?.length <= 0) {
                    setLoading(false)
                    toastError("Please select atleast one product !!!");
                    return
                }


                if (selectedProducts.some(el => ((el.itemallowvariation == "1" && !el.selectedVariant)))) {
                    setLoading(false)
                    toastError("Some of the products require a variant to be selected before moving forward");
                    return
                }


                if (selectedProducts.some(el => ((el.itemallowvalidaddon == "1" && (!el.addonArr || el.addonArr.length == 0))))) {
                    setLoading(false)
                    toastError("Some of the products require atleast 1 addon to be selected before moving forward");
                    return
                }

                if (selectedProducts && selectedProducts?.length <= 0 && handleIfMinAddonsAreSelected()) {
                    setLoading(false)
                    toastError("Some of the products have a minimum addon required please fullfill this condition to move forward");
                    return
                }

                console.log(recomendationCategoryObj.isCombo, "recomendationCategoryObj.isCombo")
                if (recomendationCategoryObj.isCombo == true && image == "") {
                    setLoading(false)
                    toastError("image cannot be empty");
                    return
                }

                let tempArr = selectedProducts.map((el) => {
                    let tempObj = {
                        itemid: el.itemid,
                        itemname: el.itemname,
                        price: handleSetProductPrice(el),
                        AddonItem: `${el?.addonArr ? JSON.stringify(el?.addonArr) : ""}`,
                        addonArr: el.addonArr ? el.addonArr : [],
                        fixed_per: ""
                    }


                    if (el.selectedVariant) {

                        tempObj = {
                            id: el.selectedVariant.id,
                            itemid: el.selectedVariant.id,
                            name: el.selectedVariant.name,
                            itemname: `${el.itemname} (${el.selectedVariant.name})`,
                            price: el.selectedVariant.price,
                            AddonItem: `${el?.addonArr ? JSON.stringify(el?.addonArr) : ""}`,
                            addonArr: el.addonArr ? el.addonArr : [],
                            variationid: el.selectedVariant.variationid,
                            fixed_per: ""
                        }
                    }



                    return tempObj
                })





                let obj = {
                    price,
                    isVeg,
                    isCombo: recomendationCategoryObj?.isCombo,
                    recomendationCategoryId: recomendationCategoryObj?._id,
                    petPujaTableId: searchParams.get("tableId"),
                    petPujaRestaurantId: searchParams.get("restaurantId"),
                    productsArr: tempArr
                }
                if (recomendationCategoryObj?.isCombo) {
                    obj.image = image
                }
                console.log(obj, selectedProducts, "selectedCategoriesselectedCategories")
                if (!isEditing) {
                    let { data: res } = await addRecomendation(obj)
                    if (res.message) {
                        toastSuccess(res.message)
                        setLoading(false)
                    }
                }
                else {
                    let { data: res } = await updateRecomendationById(obj, searchParams.get("MainCategoryId"))
                    if (res.message) {
                        toastSuccess(res.message)
                        setLoading(false)
                    }
                }
            }
            else {


                if (selectedProducts && selectedProducts?.length <= 0) {
                    setLoading(false)
                    toastError("Please select atleast one product !!!");
                    return
                }





                let tempArr = selectedProducts.map((el) => {
                    let tempObj = {
                        itemid: el.itemid,
                        itemname: el.itemname,
                        price: handleSetProductPrice(el),
                        AddonItem: `${el?.addonArr ? JSON.stringify(el?.addonArr) : ""}`,
                        addonArr: el.addonArr ? el.addonArr : [],
                        fixed_per: ""
                    }


                    if (el.selectedVariant) {

                        tempObj = {
                            id: el.selectedVariant.id,
                            itemid: el.selectedVariant.id,
                            name: el.selectedVariant.name,
                            itemname: `${el.itemname} (${el.selectedVariant.name})`,
                            price: el.selectedVariant.price,
                            AddonItem: `${el?.addonArr ? JSON.stringify(el?.addonArr) : ""}`,
                            addonArr: el.addonArr ? el.addonArr : [],
                            variationid: el.selectedVariant.variationid,
                            fixed_per: ""
                        }
                    }



                    return tempObj
                })





                let obj = {
                    price,
                    image,
                    isCombo: recomendationCategoryObj?.isCombo,
                    recomendationCategoryId: recomendationCategoryObj?._id,
                    isVeg,
                    petPujaTableId: searchParams.get("tableId"),
                    petPujaRestaurantId: searchParams.get("restaurantId"),
                    productsArr: tempArr
                }
                console.log(obj, selectedProducts, "selectedCategoriesselectedCategories")
                if (!isEditing) {
                    let { data: res } = await addRecomendation(obj)
                    if (res.message) {
                        toastSuccess(res.message)
                        setLoading(false)
                    }
                }
                else {
                    let { data: res } = await updateRecomendationById(obj, searchParams.get("MainCategoryId"))
                    if (res.message) {
                        toastSuccess(res.message)
                        setLoading(false)
                    }
                }


            }

        }
        catch (err) {
            toastError(err)
            console.log(err)
            setLoading(false)
        }
    }



















    const handleGetRecomendation = async (id) => {
        try {
            setLoading(true)
            let { data: res } = await getRecomendationById(id);
            if (res.data) {
                setLoading(false)
                setImage(res.data.image);


                let tempProducts = productsArr.filter(el => res.data.productsArr.some(ele => ele.itemid == el.itemid))


                for (const el of tempProducts) {

                    let tempAddonsArr = await getAddon(el.itemid, "");
                    el.addonsArr = tempAddonsArr
                }







                setSelectedProducts(tempProducts)
                // setProductsArr(res.data.map((el, index) => ({ label: el.itemname, value: el._id, ...el })));
                // setMainProductsArr(res.data.map((el, index) => ({ label: el.itemname, value: el._id, ...el })));
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    };



















    const handleGetProductsByTableId = async (id) => {
        try {
            setLoading(true)
            let { data: res } = await getTableMenuById(id);
            if (res.data) {
                setLoading(false)
                setProductsArr(res.data.map((el, index) => ({ label: el.itemname, value: el._id, ...el })));
                setMainProductsArr(res.data.map((el, index) => ({ label: el.itemname, value: el._id, ...el })));
            }
        } catch (err) {
            setLoading(false)
            toastError(err);
        }
    };





    const getAddon = async (productId, variantId = '') => {
        try {
            setAddonsLoading(true)

            let obj = {
                restaurantId: searchParams.get("restaurantId"),
                tableId: searchParams.get("tableId"),
                tableNo: searchParams.get("tableNo"),
                productId: productId,
                variantId: variantId,
            }

            let query = new URLSearchParams(obj).toString()
            const res = await getAddons(query)

            if (res.data?.data) {
                setAddonsLoading(false)
                return res.data.data
                // setAddonArray(res.data?.data)
            }

            // console.log(res.data)
        } catch (error) {
            setAddonsLoading(false)
            toastError(error)
        }
    }


    useEffect(() => {
        if (searchParams.get("tableId")) {

            handleGetProductsByTableId(searchParams.get("tableId"))
        }
    }, [searchParams.get("tableId")])

    useEffect(() => {
        getRecomendationCategoryObj()
    }, [])


    useEffect(() => {
        if (searchParams.get("recomedationId") && productsArr && productsArr?.length > 0) {
            handleGetRecomendation(searchParams.get("recomedationId"))
        }
    }, [searchParams.get("recomedationId"), productsArr])


    useEffect(() => {
        if (isVeg) {
            let tempArr = mainProductsArr.filter(el => el.item_attributeid == "1")
            setProductsArr(tempArr)
        }
        else {
            setProductsArr(mainProductsArr);
        }

    }, [productsArr, isVeg])




    const handleSelectVariant = (obj, productObj) => {

        let tempArr = selectedProducts;

        let productsIndex = tempArr.findIndex(el => el._id == productObj._id);

        if (productsIndex != -1) {
            tempArr[productsIndex].selectedVariant = obj
        }

        setSelectedProducts(_.cloneDeep(tempArr))
    }


    const handleGetAddOnsForTable = async (varaintObj, productObj) => {
        let tempAddonsArr = await getAddon(productObj.itemid, varaintObj.id);
        let tempArr = selectedProducts;

        let productsIndex = tempArr.findIndex(el => el._id == productObj._id);

        if (productsIndex != -1) {
            tempArr[productsIndex].addonsArr = tempAddonsArr
        }


        setSelectedProducts(_.cloneDeep(tempArr))

    }



    const selectAddon = async (groupObj, addonObj, productObj) => {
        try {

            let obj = {
                group_id: groupObj.addongroupid,
                group_name: groupObj.addongroup_name,
                id: addonObj.addonitemid,
                name: addonObj.addonitem_name,
                price: addonObj.addonitem_price,
                quantity: 1,
            }

            let tempArr = selectedProducts;

            let productsIndex = tempArr.findIndex(el => el._id == productObj._id);

            if (productsIndex != -1) {
                let addOnIndex = -1


                let checkedAddons = tempArr[productsIndex]?.addonArr?.filter(elx => elx.group_id == groupObj.addongroupid);
                // console.log(checkedAddons, "checkedAddons")



                // console.log(groupObj, "checkedAddons groupObj")


                // console.log(addOnIndex, "tempAddOnArr addOnIndex 1")
                if (tempArr[productsIndex]?.addonArr && tempArr[productsIndex]?.addonArr?.length > 0) {
                    addOnIndex = tempArr[productsIndex]?.addonArr?.findIndex(el => (el.group_id === obj.group_id && el.id == obj.id))
                }
                // console.log(addOnIndex, "tempAddOnArr addOnIndex")
                if (addOnIndex == -1) {
                    if (groupObj.max_qty < (checkedAddons && checkedAddons?.length + 1)) {
                        toastError("Maximum amount of addons added for this add on group")
                        return
                    }
                    else {
                        if (tempArr[productsIndex].addonArr && tempArr[productsIndex].addonArr?.length > 0) {
                            tempArr[productsIndex].addonArr = [...tempArr[productsIndex].addonArr, { ...obj }]
                        }
                        else {
                            tempArr[productsIndex].addonArr = [{ ...obj }]
                        }

                    }

                }
                else {
                    tempArr[productsIndex].addonArr = tempArr[productsIndex]?.addonArr?.filter(el => !(el.group_id === obj.group_id && el.id == obj.id))
                }

            }

            // console.log(tempArr, "tempAddOnArr")

            setSelectedProducts(_.cloneDeep(tempArr))






        } catch (error) {
            toastError(error)
        }
    }


    const handleReturnPrice = (productObj) => {
        let finalPrice = 0
        finalPrice = finalPrice + parseInt(productObj.price)
        if (productObj.addonArr && productObj.addonArr.length > 0) {
            finalPrice = parseInt(finalPrice) + productObj.addonArr.reduce((acc, el) => acc + parseInt(el.price), 0)
        }

        if (productObj?.selectedVariant) {
            finalPrice = parseInt(finalPrice) + parseInt(productObj.selectedVariant.price)
        }

        // console.log(productObj, "productObj")

        return finalPrice
    }


    useEffect(() => {
        console.log(selectedProducts, "SELECTED PRODUCTS")
        handleGetTotalPrice()
    }, [selectedProducts])

    const handleGetTotalPrice = () => {
        let finalPrice = selectedProducts.reduce((acc, el) => acc + (
            parseInt(el.price) +
            parseInt((el.selectedVariant && el.selectedVariant.price) ?
                parseInt(el.selectedVariant.price) : 0) + parseInt((el.addonArr && el.addonArr.length) ? el.addonArr.reduce((acc, elx) => acc + parseInt(elx.price), 0) : 0)), 0)
        // console.log(finalPrice, "finalPrice")
        setPrice(finalPrice);
    }



    const handleCheckIfVariantIsSelected = (obj, variantObj) => {
        // console.log(obj, "obj")
        if (obj.selectedVariant && obj.selectedVariant) {

        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    {recomendationCategoryObj?.isCombo

                        ?
                        <>
                            <DashboardBox>
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="" className="my-3">Products</label>
                                        <Select isMulti closeMenuOnSelect={false} onChange={(e) => setSelectedProducts(e)} options={productsArr} value={selectedProducts} />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="" className="my-3">Price</label>
                                        <input disabled value={`${price}`} type="number" className="form-control" />
                                    </div>



                                    {
                                        selectedProducts && selectedProducts.length > 0 && selectedProducts.map((el, index) => {
                                            return (
                                                <div key={index} className="border rounded p-3 mx-2 my-3">
                                                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", margin: "10px 0px" }}>
                                                        <div>Added {el.itemname}</div>
                                                        <div>INR {handleReturnPrice(el)}</div>
                                                    </div>

                                                    {
                                                        el.itemallowvariation == "1" &&
                                                        <div className="border rounded p-3 mx-2 my-3">
                                                            <div>Please Select variants</div>
                                                            {
                                                                el.variation && el.variation.length > 0 && el.variation.map((ele, indexX) => {
                                                                    return (
                                                                        <div key={indexX} style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", margin: "10px 0px" }}>
                                                                            <div>
                                                                                <input type="radio" name="variation" value={handleCheckIfVariantIsSelected(el, ele)} onChange={(e) => handleSelectVariant(ele, el)} id={`variation${indexX}`} /> <label style={{ marginLeft: 10 }} htmlFor={`variation${indexX}`}>{el.itemname} ({ele.name})</label>
                                                                            </div>
                                                                            <div>
                                                                                INR {ele.price}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        el.itemallowvalidaddon == "1" &&
                                                        <div className="border rounded p-3 mx-2 my-3">
                                                            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", margin: "10px 0px" }}>
                                                                <div>Please Select Add ons</div>
                                                                <CustomButton btnName={"View Addons"} ClickEvent={() => handleGetAddOnsForTable({ id: "" }, el)} isBtn noIcon path="/Recomendations/Add" />
                                                            </div>


                                                            {
                                                                addonsLoading &&
                                                                <div>Loading addons...</div>
                                                            }

                                                            {
                                                                el.addonsArr && el.addonsArr.length > 0 && el.addonsArr.map((ele, indexX) => {
                                                                    // console.log(ele, "ele")
                                                                    return (
                                                                        <div key={indexX}>
                                                                            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", margin: "10px 0px" }}>
                                                                                <label><b> {ele.addongroup_name} (minimin - {ele.min_qty} and maximum - {ele.max_qty})</b></label>
                                                                            </div>

                                                                            {
                                                                                ele.addongroupitems && ele.addongroupitems.length > 0 && ele.addongroupitems.map((elx, indexXX) => {
                                                                                    return (
                                                                                        <div key={indexXX} style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", margin: "10px 0px" }}>
                                                                                            <div>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    checked={el.addonArr && el.addonArr.some(elm => (elm.id == elx.addonitemid && elm.group_id == ele.addongroupid))}
                                                                                                    onChange={(e) => selectAddon(ele, elx, el)}
                                                                                                    id={`variation${indexXX}${indexX}`}
                                                                                                />
                                                                                                <label
                                                                                                    style={{ marginLeft: 10 }}
                                                                                                    htmlFor={`variation${indexXX}${indexX}`}>
                                                                                                    {elx.addonitem_name}
                                                                                                </label>
                                                                                            </div>
                                                                                            <div>
                                                                                                INR {elx.addonitem_price}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }


                                                </div>
                                            )
                                        })
                                    }


                                    <div className="col-6 ">
                                        <label htmlFor="" className="my-3">Type</label>
                                        <div style={{ display: "flex" }}>
                                            <Switch onChange={(e) => { setIsVeg(e); setSelectedProducts([]) }} checked={isVeg} />
                                            <span style={{ marginLeft: 30 }}>{isVeg ? "Is veg" : "Is Non veg"}</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="" className="my-3">Image</label>
                                        {image && `${image}` != "" &&
                                            <div className="my-3">
                                                {
                                                    image && `${image}`.includes("base64") ? <img style={{ height: 150, width: 150 }} src={image} /> : <img style={{ height: 150, width: 150 }} src={generateFilePath(image)} />
                                                }
                                            </div>
                                        }
                                        <FileUpload accepts="image/*" onFileChange={setImage} />
                                    </div>

                                    <div className="col-12 mt-4">
                                    </div>
                                </div>
                                <CustomButton btnName={"Close"} ClickEvent={() => navigate(-1)} isBtn extraClass={"me-3"} iconName="fa-solid fa-close" path="/" />
                                <CustomButton btnName={"Submit"} ClickEvent={() => handleAdd()} isBtn iconName="fa-solid fa-pencil" path="/" />
                            </DashboardBox>
                        </>
                        :
                        <>
                            <DashboardBox>
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="" className="my-3">Products</label>
                                        <Select onChange={(e) => { console.log(e); setSelectedProducts([{ ...e }]) }} options={productsArr} value={selectedProducts} />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="" className="my-3">Price</label>
                                        <input disabled value={`${price}`} type="number" className="form-control" />
                                    </div>






                                    <div className="col-6 ">
                                        <label htmlFor="" className="my-3">Type</label>
                                        <div style={{ display: "flex" }}>
                                            <Switch onChange={(e) => { setIsVeg(e); setSelectedProducts([]) }} checked={isVeg} />
                                            <span style={{ marginLeft: 30 }}>{isVeg ? "Is veg" : "Is Non veg"}</span>
                                        </div>
                                    </div>
                                    {/* <div className="col-12">
                                        <label htmlFor="" className="my-3">Image</label>
                                        {image && `${image}` != "" &&
                                            <div className="my-3">
                                                {
                                                    image && `${image}`.includes("base64") ? <img style={{ height: 150, width: 150 }} src={image} /> : <img style={{ height: 150, width: 150 }} src={generateFilePath(image)} />
                                                }
                                            </div>
                                        }
                                        <FileUpload accepts="image/*" onFileChange={setImage} />
                                    </div> */}

                                    <div className="col-12 mt-4">
                                    </div>
                                </div>
                                <CustomButton btnName={"Close"} ClickEvent={() => navigate(-1)} isBtn extraClass={"me-3"} iconName="fa-solid fa-close" path="/" />
                                <CustomButton btnName={"Submit"} ClickEvent={() => handleAdd()} isBtn iconName="fa-solid fa-pencil" path="/" />
                            </DashboardBox>
                        </>
                    }

                </div>
            </div>

            {
                loading &&
                <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
                    <h1>Loading Please Wait....</h1>
                </div>
            }
        </div>
    );
}


