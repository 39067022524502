import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateCategoryById } from "../../services/category.service";
import { updateProductById } from "../../services/product.service";
import { SyncRestaurantWithApi, downloadFile, getRestaurantsById, updateRestaurantById } from "../../services/restaurant.service";
import { tempUrl, url } from "../../services/url.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import { generateFilePath } from "../Utility/utils";
import Modal from 'react-modal';
import { useSelector } from 'react-redux'
import { accessObj, rolesObj } from "../Utility/constants";

export default function AddRestaurants() {
  const authObj = useSelector(state => state.auth)
  const [video, setVideo] = useState("");
  const [firstVideo, setFirstVideo] = useState("");
  const [restaurantObj, setRestaurantObj] = useState({});
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [restaurantID, setRestaurantID] = useState("");

  const [tabDisplayed, setTabDisplayed] = useState("Tables");

  const activeBtnStyles = { marginRight: 10, cursor: "pointer", color: "white", backgroundColor: "black", display: "grid", placeItems: "center", fontSize: 20, padding: "10px 20px", borderRadius: 10 };
  const inActiveBtnStyles = { marginRight: 10, cursor: "pointer", border: "solid 1px grey", display: "grid", placeItems: "center", fontSize: 20, padding: "10px 20px", borderRadius: 10 };

  const [searchParams, setSearchParams] = useSearchParams();
  const handleGetRestaurantsById = async (id) => {
    try {
      setLoading(true)
      let { data: res } = await getRestaurantsById(id);
      if (res.data) {
        setLoading(false)
        setRestaurantObj(res.data);
        setVideo(res.data.video);
        setFirstVideo(res.data.firstVideo);
      }
    } catch (err) {
      setLoading(false)
      toastError(err);
    }
  };

  const handleUpdateRestaurantById = async () => {
    try {
      setLoading(true)
      let obj = {
        video: video,
        firstVideo: firstVideo,
      };
      let { data: res } = await updateRestaurantById(obj, restaurantID);
      if (res.message) {
        setLoading(false)
        toastSuccess(res.message);
        handleGetRestaurantsById(restaurantID);
        // setRestaurantObj(res.data);
      }
    } catch (err) {
      setLoading(false)
      toastError(err);
    }
  };

  useEffect(() => {
    if (restaurantID) {
      handleGetRestaurantsById(restaurantID);
    }
  }, [restaurantID]);

  useEffect(() => {
    if (searchParams.get("restaurantId")) {
      setRestaurantID(searchParams.get("restaurantId"));
    }
  }, [searchParams.get("restaurantId")]);

  const handleFindCategoryName = (catId) => {
    if (restaurantObj.categories) {
      let value = restaurantObj.categories.find((el) => el.categoryid == catId);
      if (value) {
        return value.categoryname;
      } else {
        return "NA";
      }
    }
  };


  const handleCategoryImageAdd = async (e, itemid) => {
    try {
      setLoading(true)
      // alert("ASd")
      let obj = {
        category_image_url: e,
      };
      let { data: res } = await updateCategoryById(obj, itemid);
      if (res.message) {
        setLoading(false)
        toastSuccess(res.message);
        handleGetRestaurantsById(restaurantID);
        // setRestaurantObj(res.data);
      }
    } catch (err) {
      setLoading(false)
      toastError(err);
    }
  };

  const handleProductVideoAdd = async (e, itemid) => {
    try {
      setLoading(true)
      // alert("ASd")
      let obj = {
        video: e,
      };
      let { data: res } = await updateProductById(obj, itemid);
      if (res.message) {
        setLoading(false)
        toastSuccess(res.message);
        handleGetRestaurantsById(restaurantID);
        // setRestaurantObj(res.data);
      }
    } catch (err) {
      toastError(err);
      setLoading(false)
    }
  };



  const category_sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "Name",
      cell: (row) => <p>{row?.categoryname}</p>,
      width: "20%",
    },
    {
      name: "Image",
      cell: (row) => (
        <p>
          {row?.category_image_url ? (
            <>
              <a href={generateFilePath(row.category_image_url)} target="_blank">
                <img style={{ height: 100 }} src={generateFilePath(row.category_image_url)} alt="" />
              </a>
              <div className="my-4">
                <FileUpload onFileChange={(e) => handleCategoryImageAdd(e, row._id)} />
              </div>
            </>
          ) : (
            <FileUpload onFileChange={(e) => handleCategoryImageAdd(e, row._id)} />
          )}{" "}
        </p>
      ),
      width: "20%",
    },
    // {
    //     name: "Action",
    //     width: "15%",
    //     cell: (row) => (
    //         <>
    //             <ActionIcon
    //                 Uniquekey={row.id}
    //                 edit
    //                 isRedirected={true}
    //                 editPath={`/Restaurants/Edit?restaurantId=${row._id}`}
    //             />
    //         </>
    //     ),
    // },
  ];

  const checkDisplayPermissions = (checkPermission) => {
    if (authObj?.role == rolesObj.SUBADMIN) {
      let tempArr = authObj?.user?.accessArr;
      if (tempArr.some(el => el?.value == checkPermission)) {
        return true
      }
      else {
        return false
      }
    }
    else if (authObj?.role == rolesObj?.ADMIN) {
      return true
    }
  }
  const handleQrDownload = async (tableId, tableNo) => {
    try {
      setLoading(true)
      const { data: res } = await downloadFile(`${encodeURIComponent(`${tempUrl}?restId=${restaurantObj?.menu_sharing_code}&tableId=${tableId}&tableNo=${tableNo}`)}`);
      if (res) {
        console.log(res);
        setLoading(false)
        downloadURI(`${url}/qr${res.file.fileName}`, res.file.fileName);
      }
    } catch (error) {
      setLoading(false)
      toastError(error);
    }
  };

  const handleSyncMenu = async () => {
    setLoading(true)
    try {
      toastSuccess("Syncing Please wait")
      const { data: res } = await SyncRestaurantWithApi(restaurantObj?.menu_sharing_code);
      if (res) {
        setLoading(false)
        toastSuccess(res.message)
        if (restaurantID && restaurantID != "")
          handleGetRestaurantsById(restaurantID);

      }
    } catch (error) {
      setLoading(false)
      toastError(error)
    }
  }


  function downloadURI(uri, name) {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.target = "_blank";
    // link.download =


    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
  }

  const table_sale_columns = [
    {
      name: "SL",
      selector: (row, index) => `${index + 1}.)`,
      sortable: true,
      width: "6%",
    },
    {
      name: "Table No",
      cell: (row) => <p>{row?.table_no}</p>,
      width: "20%",
    },
    {
      name: "Status (Is active/not active)",
      cell: (row) => <p>{row?.active == "1" ? "Active" : "In-active"}</p>,
      width: "20%",
    },
    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <CustomButton isLink iconName="fa-solid fa-eye" btnName="View Table Products" path={`/Product/View?tableId=${row.id}&tableNo=${row.table_no}&restaurantId=${restaurantObj?.menu_sharing_code}`} />
          {
            checkDisplayPermissions(accessObj?.download_qr_code) &&
            <CustomButton btnName={"Download Table Qr Code"} extraClass={"ms-4"} isBtn ClickEvent={() => handleQrDownload(row.id, row.table_no)} />
          }
        </>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <DashboardBox>
            <div className="row mb-5">
              <label htmlFor="" className="mb-3">Icons/Disclaimer</label>
              <div className="col">
                <UpdateIcons restObj={restaurantObj} restId={restaurantID} />
              </div>
            </div>
            <div className="row mb-5">
              <label htmlFor="" className="mb-3">Update Restaurant Details</label>
              <div className="col">
                <CustomButton btnName={"Update Restaurant Details"} ClickEvent={() => navigate(`/Restaurants/Edit/${searchParams.get("restaurantId")}`)} isBtn iconName="fa-solid fa-pencil" />

              </div>

            </div>
            {checkDisplayPermissions(accessObj?.sync_menu) &&

              <div className="row mb-5">
                <label htmlFor="" className="mb-3">Sync Menu</label>
                <div className="col">
                  <CustomButton btnName={"Sync Menu"} ClickEvent={() => handleSyncMenu()} isBtn iconName="fa-solid fa-pencil" />

                </div>

              </div>
            }
            <div className="row">
              {/* <div className="col-6">
                <label htmlFor="">First Screen Video/Intro Video</label>
                <div>
                  {firstVideo && `${firstVideo}`.includes("base64") ? <video style={{ height: 150, width: 150 }} src={firstVideo} controls></video> : <video style={{ height: 150, width: 150 }} src={generateFilePath(firstVideo)} controls></video>}
                </div>
                <FileUpload accepts="video/mp4,video/x-m4v,video/*" onFileChange={setFirstVideo} />
              </div> */}
              <div className="col-6">
                <label htmlFor="">Restaurant Video</label>
                <div>
                  {video && `${video}`.includes("base64") ? <video style={{ height: 150, width: 150 }} src={video} controls></video> : <video style={{ height: 150, width: 150 }} src={generateFilePath(video)} controls></video>}
                </div>
                <FileUpload accepts="video/mp4,video/x-m4v,video/*" onFileChange={(e) => { setVideo(e); console.log(e, "result") }} />
              </div>
              <div className="col-6"></div>
              <div className="col-6 mt-4">
                <CustomButton btnName={"Update Restaurant Video"} ClickEvent={() => handleUpdateRestaurantById()} isBtn iconName="fa-solid fa-pencil" path="/Restaurants/Edit" />
              </div>
              <div className="col-12 mt-4">
                <h4>Tables</h4>
                <div className="row">
                  <DataTable columns={table_sale_columns} data={restaurantObj.tableArr && restaurantObj.tableArr.length > 0 ? restaurantObj.tableArr : []} pagination />
                </div>
              </div>
            </div>
          </DashboardBox>
        </div>
      </div>
      {
        loading &&
        <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
          <h1>Loading Please Wait....</h1>
        </div>
      }
    </div>
  );
}


const UpdateIcons = ({ restObj, onSubmit, restId }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [logo, setLogo] = useState("")
  const [alcoholicIcon, setAlcoholicIcon] = useState("")
  const [nonAlcoholicIcon, setNonAlcoholicIcon] = useState("")
  const [vegIcon, setVegIcon] = useState("")
  const [nonVegIcon, setNonVegIcon] = useState("")
  const [beverageIcon, setBeverageIcon] = useState("")
  const [foodIcon, setFoodIcon] = useState("")
  const [viewBillIcon, setViewBillIcon] = useState("");
  const [orderMoreIcon, setOrderMoreIcon] = useState("");


  const [disclaimer, setDisclaimer] = useState("")

  useEffect(() => {
    if (restObj?.disclaimer) {
      setDisclaimer(restObj?.disclaimer)
      setLogo(restObj?.logo)
      setAlcoholicIcon(restObj?.alcoholicIcon)
      setNonAlcoholicIcon(restObj?.nonAlcoholicIcon)
      setVegIcon(restObj?.vegIcon)
      setNonVegIcon(restObj?.nonVegIcon)
      setBeverageIcon(restObj?.beverageIcon)
      setFoodIcon(restObj?.foodIcon)
      setViewBillIcon(restObj?.viewBillIcon)
      setOrderMoreIcon(restObj?.orderMoreIcon)
    }
  }, [restObj?.disclaimer])


  const handleSubmit = async () => {
    try {
      setLoading(true)
      let obj = {
        logo,
        alcoholicIcon,
        nonAlcoholicIcon,
        vegIcon,
        nonVegIcon,
        beverageIcon,
        foodIcon,
        disclaimer,
        viewBillIcon,
        orderMoreIcon,
      }
      const res = await updateRestaurantById(obj, restId)
      if (res.data?.message)
        setLoading(false)
      toastSuccess(res.data?.message)

    } catch (error) {
      setLoading(false)
      toastError(error)
    }

  }



  return (
    <div>
      <CustomButton btnName={"Update Icons/Disclaimer"} ClickEvent={() => openModal()} isBtn iconName="fa-solid fa-pencil" />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 10,
            minWidth: '45vw'
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.7)'
          }
        }}
        contentLabel="Example Modal"
      >
        <div style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
          <div >

            <h5>Update:</h5>

            <div className="mt-2">Logo :</div>
            {
              logo && logo.includes("base64") ?
                <img src={logo} style={{ maxWidth: 200, maxHeight: 200 }} />
                :
                <img src={generateFilePath(logo)} style={{ maxWidth: 200, maxHeight: 200 }} />
            }
            <FileUpload accepts="image/*" onFileChange={setLogo} />

            <div className="mt-2">Disclaimer:</div>
            <textarea name="" id="" rows="3" value={disclaimer} onChange={(e) => setDisclaimer(e.target.value)} style={{ width: '100%' }} />

            <div className="mt-2">Alcohol Icon:</div>
            {
              alcoholicIcon && alcoholicIcon.includes("base64") ?
                <img src={alcoholicIcon} style={{ maxWidth: 200, maxHeight: 200 }} />
                :
                <img src={generateFilePath(alcoholicIcon)} style={{ maxWidth: 200, maxHeight: 200 }} />
            }
            <FileUpload accepts="image/*" onFileChange={setAlcoholicIcon} />
            <div className="mt-2">Non Alcohol Icon:</div>
            {
              nonAlcoholicIcon && nonAlcoholicIcon.includes("base64") ?
                <img src={nonAlcoholicIcon} style={{ maxWidth: 200, maxHeight: 200 }} />
                :
                <img src={generateFilePath(nonAlcoholicIcon)} style={{ maxWidth: 200, maxHeight: 200 }} />
            }
            <FileUpload accepts="image/*" onFileChange={setNonAlcoholicIcon} />
            <div className="mt-2">Beverage Icon:</div>
            {
              beverageIcon && beverageIcon.includes("base64") ?
                <img src={beverageIcon} style={{ maxWidth: 200, maxHeight: 200 }} />
                :
                <img src={generateFilePath(beverageIcon)} style={{ maxWidth: 200, maxHeight: 200 }} />
            }
            <FileUpload accepts="image/*" onFileChange={setBeverageIcon} />
            <div className="mt-2">Food Icon:</div>
            {
              foodIcon && foodIcon.includes("base64") ?
                <img src={foodIcon} style={{ maxWidth: 200, maxHeight: 200 }} />
                :
                <img src={generateFilePath(foodIcon)} style={{ maxWidth: 200, maxHeight: 200 }} />
            }
            <FileUpload accepts="image/*" onFileChange={setFoodIcon} />
            <div className="mt-2">Veg Icon:</div>
            {
              vegIcon && vegIcon.includes("base64") ?
                <img src={vegIcon} style={{ maxWidth: 200, maxHeight: 200 }} />
                :
                <img src={generateFilePath(vegIcon)} style={{ maxWidth: 200, maxHeight: 200 }} />
            }
            <FileUpload accepts="image/*" onFileChange={setVegIcon} />
            <div className="mt-2">Non Veg Icon:</div>
            {
              nonVegIcon && nonVegIcon.includes("base64") ?
                <img src={nonVegIcon} style={{ maxWidth: 200, maxHeight: 200 }} />
                :
                <img src={generateFilePath(nonVegIcon)} style={{ maxWidth: 200, maxHeight: 200 }} />
            }
            <FileUpload accepts="image/*" onFileChange={setNonVegIcon} />


            <div className="mt-2">View bill:</div>
            {
              viewBillIcon && viewBillIcon.includes("base64") ?
                <img src={viewBillIcon} style={{ maxWidth: 200, maxHeight: 200 }} />
                :
                <img src={generateFilePath(viewBillIcon)} style={{ maxWidth: 200, maxHeight: 200 }} />
            }
            <FileUpload accepts="image/*" onFileChange={setViewBillIcon} />
            <div className="mt-2">Order More:</div>
            {
              orderMoreIcon && orderMoreIcon.includes("base64") ?
                <img src={orderMoreIcon} style={{ maxWidth: 200, maxHeight: 200 }} />
                :
                <img src={generateFilePath(orderMoreIcon)} style={{ maxWidth: 200, maxHeight: 200 }} />
            }
            <FileUpload accepts="image/*" onFileChange={setOrderMoreIcon} />


            <CustomButton btnName={"Submit"} ClickEvent={() => handleSubmit()} isBtn extraClass={'m-2'} />
            <CustomButton btnName={"Close"} ClickEvent={() => closeModal()} isBtn />

          </div>

        </div>
      </Modal>
      {
        loading &&
        <div style={{ position: "absolute", top: 0, left: 0, height: "100vh", width: "100vw", backgroundColor: "white", display: "grid", placeItems: "center" }}>
          <h1>Loading Please Wait....</h1>
        </div>
      }

    </div>
  );



}