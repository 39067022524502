import axios from "axios";
import { url } from "./url.service";

const serverUrl = url + "/maincategory";

export const addmaincategory = (formData) => {
    return axios.post(serverUrl + "/", formData);
};

export const getmaincategories = (query) => {
    return axios.get(`${serverUrl}/?${query}`);
};

export const getmaincategoryById = (id) => {
    return axios.get(`${serverUrl}/getById/${id}`);
};

export const deletemaincategoryById = (id) => {
    return axios.delete(`${serverUrl}/deleteById/${id}`);
};

export const updatemaincategoryById = (formData, id) => {
    return axios.patch(`${serverUrl}/updateById/${id}`, formData);
};