export const generalModelStatuses = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
};


export const rolesObj = {
  ADMIN: "ADMIN",
  CARPENTER: "CARPENTER",
  RESTAURANT: "RESTAURANT",
  SUBADMIN: "SUBADMIN"
};


export const accessArr = [{ label: "Add Restaurant", value: "add_restaurant" }, { label: "Edit Restaurant", value: "edit_restaurant" }, { label: "Add Videos", value: "add_videos" }, { label: "Add Images", value: "add_images" }, { label: "View Reports", value: "view_reports" }, { label: "Sync Menu", value: "sync_menu" }, { label: "Download Qr Code", value: "download_qr_code" }]
export const accessObj = { add_restaurant: "add_restaurant", edit_restaurant: "edit_restaurant", add_videos: "add_videos", add_images: "add_images", view_reports: "view_reports", sync_menu: "sync_menu", download_qr_code: "download_qr_code" }

export const razorPayBusinessType = {
  llp: "llp",
  ngo: "ngo",
  other: "other",
  individual: "individual",
  partnership: "partnership",
  proprietorship: "proprietorship",
  public_limited: "public_limited",
  private_limited: "private_limited",
  trust: "trust",
  society: "society",
  not_yet_registered: "not_yet_registered",
  educational_institutes: "educational_institutes",
}

export const razorPaySubCategory = {
    online_food_ordering: "online_food_ordering",
    restaurant: "restaurant",
    food_court: "food_court",
    catering: "catering",
    alcohol: "alcohol",
    restaurant_search_and_booking: "restaurant_search_and_booking",
    dairy_products: "dairy_products",
    bakeries: "bakeries",
}
